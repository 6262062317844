export default function ({redirect, route, store}) {
    const hasAccess = route.meta.reduce(
        (hasAccess, {permissions: {companyType, role}}) =>
            hasAccess &&
            companyType === store.state.auth.companyType &&
            role.includes(store.getters['auth/personRole']),
        true
    )
    if (!hasAccess) redirect({name: 'home'})
}
