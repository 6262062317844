import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {appInstance} from '~/utils/app-accessor'
import Vue from 'vue'
import {orderFilters, ordersResponse} from '@/utils/store-blank-states'
import {B2B_AUTH_EVENT, CHANGE_LOCALE_EVENT, EventBus, RESET, RESET_BOOKING_DATA} from '@/utils/event-bus'
import {authStore, persistentStore} from '@/store'

@Module({name: 'runtime', stateFactory: true, namespaced: true})
export default class RuntimeStore extends VuexModule {
    loading = false
    authActive = false
    ordersResponse = ordersResponse()
    orderDetails = {}
    bookingActive = false
    conditionsLoading = {}
    persons = []
    clients = []
    companies = []
    touristBookingFields = {bookingFields: {}}
    productBookingFields = {}
    exchangeRates = {}
    countries = []
    restrictedCountries = []
    clientsCount = 0
    //For mod extension (can't extend state params in VuexModule)
    config = {}

    @Mutation
    SET_ORDERS_RESPONSE(ordersResponse) {
        const {orders, ...rs} = ordersResponse
        this.ordersResponse = {orders: orders.map(order => Object.freeze(order)), ...rs}
    }

    @Mutation
    SET_CONDITIONS_LOADING({offerKey, cts}) {
        Vue.set(this.conditionsLoading, offerKey, cts)
    }

    @Mutation
    SET_BOOKING_ACTIVE(active) {
        this.bookingActive = active
    }

    @Mutation
    SET_ORDER_DETAILS(data) {
        this.orderDetails = data
    }

    @Mutation
    SET_LOADING(loading) {
        this.loading = loading
    }

    @Mutation
    SET_PERSONS(persons) {
        this.persons = persons
    }

    @Mutation
    UPDATE_PERSON({index, val}) {
        Object.assign(this.persons[index], val)
    }

    @Mutation
    SET_CLIENTS(clients) {
        this.clients = clients
    }

    @Mutation
    SET_CLIENTS_COUNT(count) {
        this.clientsCount = count
    }

    @Mutation
    SET_COMPANIES(companies) {
        this.companies = companies
    }

    @Mutation
    UPDATE_ORDER({orderId, data}) {
        const index = this.ordersResponse.orders.findIndex(order => order.orderId === orderId)
        Vue.set(this.ordersResponse.orders, index, Object.freeze({...this.ordersResponse.orders[index], ...data}))
    }

    @Mutation
    UPDATE_SERVICE_COMMENT({serviceId, comment}) {
        const serviceIndex = this.orderDetails.services.findIndex(service => service.serviceId === serviceId)

        Vue.set(this.orderDetails.services[serviceIndex], 'comments', comment)
    }

    @Mutation
    SET_AUTH_ACTIVE(active) {
        this.authActive = active
    }

    @Mutation
    RESET() {
        this.ordersResponse = ordersResponse()
        this.orderDetails = {}
        this.persons = []
    }

    @Mutation
    SET_DEFAULT_TOURIST_BOOKING_FIELDS() {
        this.touristBookingFields = {
            bookingFields: {
                BIRTHDATE: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'MANDATORY',
                },
                CITIZENSHIP: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                EMAIL: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                PASSPORT_EXPIRE_DATE: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                PASSPORT_ISSUE_DATE: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                PASSPORT_NUMBER: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                PHONE: {
                    mainTouristStatus: 'DISABLED',
                    allTouristsStatus: 'DISABLED',
                    childrenStatus: 'DISABLED',
                },
                PREFIX: {
                    mainTouristStatus: 'OPTIONAL',
                    allTouristsStatus: 'OPTIONAL',
                    childrenStatus: 'OPTIONAL',
                },
            },
        }
    }

    // TODO: temporary solution for cars and tours
    @Mutation
    SET_CUSTOM_TOURIST_BOOKING_FIELDS(bookingFields) {
        this.touristBookingFields = {bookingFields}
    }

    @Mutation
    SET_TOURIST_BOOKING_FIELDS(touristBookingFields) {
        this.touristBookingFields = touristBookingFields
    }

    @MutationAction({mutate: ['countries', 'restrictedCountries']})
    async loadCountries() {
        try {
            const [{countries}, {countries: restrictedCountries}] = await Promise.all([
                appInstance.$api.locations.get({limitCountries: 300, orderBy: 'ALPHABET', applyRestriction: false}),
                appInstance.$api.locations.get({limitCountries: 300, orderBy: 'ALPHABET'}),
            ])
            return {countries, restrictedCountries}
        } catch (e) {
            return {countries: [], restrictedCountries: []}
        }
    }

    @MutationAction({mutate: ['exchangeRates']})
    async loadExchangeRates(currencyCode) {
        if (currencyCode === 'ORIGINAL') currencyCode = appInstance.$config.currency.available[0]
        try {
            const exchangeRates = await appInstance.$api.exchangeRates.get({
                currencyCode,
                precision: 10,
            })
            return {exchangeRates}
        } catch (e) {
            return {exchangeRates: {}}
        }
    }

    @MutationAction({mutate: ['productBookingFields']})
    async loadProductBookingFields({offerKey, processId}) {
        try {
            let productBookingFields = (
                await appInstance.$api.productBookingFields.get({
                    ...(offerKey && {offerKey}),
                    ...(processId && {processId}),
                })
            ).bookingFields

            return {productBookingFields}
        } catch (e) {
            return {productBookingFields: {}}
        }
    }

    @MutationAction({mutate: ['touristBookingFields']})
    async loadTouristBookingFields({offerKey, processId}) {
        try {
            const touristBookingFields = await appInstance.$api.touristBookingFields.get({
                ...(offerKey && {offerKey}),
                ...(processId && {processId}),
            })
            //TODO It is workaround for set PASSPORT_TYPE
            /*const passportFieldKeys = [
                //'PASSPORT_ISSUE_COUNTRY',
                'PASSPORT_EXPIRE_DATE',
                'PASSPORT_ISSUE_DATE',
                'PASSPORT_NUMBER',
            ]
            const bookingFields = touristBookingFields.bookingFields*/
            //TODO Remove passport type???
            touristBookingFields.bookingFields.PASSPORT_TYPE = {
                mainTouristStatus: 'DISABLED',
                allTouristsStatus: 'DISABLED',
                childrenStatus: 'DISABLED',
            }
            return {touristBookingFields}
        } catch (e) {
            return {touristBookingFields: {bookingFields: {}}}
        }
    }

    @Action
    async loadOrders({rq, update}) {
        if (!update) {
            this.SET_LOADING(true)
        }
        try {
            const rs = await appInstance.$api.orders.get(rq)
            this.SET_ORDERS_RESPONSE(rs)
            return true
        } catch (e) {
            return false
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action({rawError: true})
    async loadOrder(rq) {
        this.SET_ORDER_DETAILS({})
        try {
            const {
                orders: [order],
            } = await appInstance.$api.orders.get(rq)
            this.SET_ORDER_DETAILS(order)
            return order
        } catch (e) {
            return e
        }
    }

    @Action
    async refreshOrder() {
        try {
            const {
                orders: [order],
            } = await appInstance.$api.orders.get({
                orderType: authStore.orderType,
                orderId: this.orderDetails.orderId,
            })
            this.SET_ORDER_DETAILS(order)
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    @Action
    async loadPersons(rq) {
        this.SET_LOADING(true)
        try {
            const [active, disabled] = await Promise.all([
                appInstance.$api.persons.get(Object.assign(rq, {active: true})),
                appInstance.$api.persons.get(Object.assign(rq, {active: false})),
            ])
            const persons = active.users.concat(disabled.users)
            this.SET_PERSONS(persons)
        } catch (e) {
            this.SET_PERSONS([])
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async updatePerson(val) {
        this.SET_LOADING(true)
        const {id} = val
        delete val.id
        try {
            //await appInstance.$api.persons.put(id, val)
            const index = this.persons.findIndex(person => person.personId === id)
            this.UPDATE_PERSON({index, val})
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async loadPrivateClients(rq) {
        this.SET_LOADING(true)
        try {
            if (rq.active === undefined) {
                const [active, disabled] = await Promise.all([
                    appInstance.$api.privateClients.get({...rq, active: true}),
                    appInstance.$api.privateClients.get({...rq, active: false}),
                ])
                this.SET_CLIENTS([...active.persons, ...disabled.persons])
            } else {
                const clients = await appInstance.$api.privateClients.get(rq)
                this.SET_CLIENTS_COUNT(clients.personsCount)
                this.SET_CLIENTS(clients.persons)
            }
        } catch (e) {
            this.SET_CLIENTS([])
            this.SET_CLIENTS_COUNT(0)
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async loadCompanies(rq) {
        this.SET_LOADING(true)
        try {
            const rs = await appInstance.$api.companies.get(rq)
            this.SET_COMPANIES(rs.object)
        } catch (e) {
            this.SET_COMPANIES([])
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async serverInit() {
        const defaultCurrency = this.store.$config.currency.default
        persistentStore.SET_CURRENCY(defaultCurrency)
        await Promise.all([this.loadExchangeRates(defaultCurrency), this.loadCountries()])
    }

    @Action
    clientInit() {
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.resetBookingData)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
    }

    @Action
    async reload() {
        await this.loadCountries()
    }

    @Action
    reset() {
        persistentStore.SET_TOURISTS([])
        persistentStore.SET_ORDER_FILTERS(orderFilters())
        persistentStore.SET_COMPANY_SETTINGS({})
        persistentStore.SET_CONDITIONS({})
        persistentStore.SET_ITINERARY_VIEW(false)
        persistentStore.SET_SELECTED_ORDER_ID(null)
        persistentStore.SET_SELECTED_AGENT(null)
        persistentStore.CLEAR_BASKET_ADD_ONS()
        this.RESET()
    }

    @Action
    resetBookingData() {
        persistentStore.SET_TOURISTS([])
        persistentStore.SET_CONDITIONS({})
    }

    get orderServiceStatus() {
        return status => {
            status = status.replace(/[\s.]/g, '_').toUpperCase()
            if (status === 'ERROR' && !authStore.isTO1) {
                return 'PENDING'
            } else {
                return status
            }
        }
    }

    get orderStatusColor() {
        return orderStatus => {
            switch (orderStatus) {
                case 'NEW':
                case 'IN_PROGRESS':
                    return 'warning--text'
                case 'COMPLETED':
                case 'COMPLETED_AND_CHECKED':
                case 'DONE':
                    return 'success--text'
                case 'CANCELLED':
                    return 'grey--text'
                case 'QUOTE':
                    return 'blue--text'
                case 'VOUCHERED':
                    return 'green--text text--darken-4'
                case 'ARCHIVED':
                    return 'blue--text text--darken-4'
                default:
                    return 'error--text'
            }
        }
    }

    get orderServiceStatusColor() {
        return orderServiceStatus => {
            switch (orderServiceStatus) {
                case 'PENDING':
                case 'CONFIRMATION_PENDING':
                case 'CANCELLATION_PENDING':
                case 'ON_CONFIRM':
                case 'CONFIRMATION_PROCESSING':
                case 'MODIFICATION_PENDING':
                case 'PENDING_TICKETING':
                case 'PENDING_TICKETING_TO1L':
                case 'TICKET_ISSUING':
                case 'PRECONFIRMED':
                    return 'warning--text'
                case 'CONFIRMED':
                case 'ISSUED':
                case 'ESTIMATED':
                    return 'success--text'
                case 'CANCELED':
                    return 'grey--text'
                case 'REJECTED':
                case 'ERROR':
                case 'CANCELLATION_REJECTED':
                case 'CANCELED_WITHOUT_FEE':
                    return 'error--text'
                case 'QUOTE':
                    return 'blue--text'
                default:
                    return 'warning--text'
            }
        }
    }

    get isConditionsLoading() {
        return offerKey => {
            if (persistentStore.conditions[offerKey]) return false
            return this.conditionsLoading[offerKey] === undefined || this.conditionsLoading[offerKey]
        }
    }

    get touristFromPerson() {
        return ({
            prefix,
            firstName,
            lastName,
            firstNameLocalized,
            lastNameLocalized,
            email,
            contactPhone,
            citizenshipId,
            personId,
            birthdate,
            role,
            passports,
        }) => {
            const tourist = {
                prefix,
                firstName: firstName || firstNameLocalized,
                lastName: lastName || lastNameLocalized,
                citizenshipId,
                email,
                phone: contactPhone,
                birthdate,
                type: 'adult',
                passport: {
                    type: null,
                    number: null,
                    issueDate: null,
                    expiryDate: null,
                },
                ...(role && {personId}),
            }
            if (passports && passports.length) {
                const {type, number, issueDate, validTill: expiryDate} = passports[0]
                Object.assign(tourist.passport, {type, number, issueDate, expiryDate})
            }
            return tourist
        }
    }

    get netPrice() {
        return ({amount, currency, originalAmount, originalCurrency, taxesAndFeesIncludedSum, serviceFee}) => ({
            amount:
                amount -
                (taxesAndFeesIncludedSum ? taxesAndFeesIncludedSum.amount : 0) -
                (serviceFee ? serviceFee.amount : 0),
            currency,
            ...(originalCurrency && {
                originalAmount:
                    originalAmount -
                    (taxesAndFeesIncludedSum && taxesAndFeesIncludedSum.originalAmount
                        ? taxesAndFeesIncludedSum.originalAmount
                        : 0) -
                    (serviceFee && serviceFee.originalAmount ? serviceFee.originalAmount : 0),
                originalCurrency,
            }),
        })
    }

    get isTouristBookingField() {
        return (fieldKey, statusKey, isMainTourist, isChild, specifiedTouristBookingFields) => {
            const bookingFields =
                specifiedTouristBookingFields?.bookingFields || this.touristBookingFields.bookingFields
            if (!bookingFields[fieldKey]) return statusKey === 'DISABLED'
            const {mainTouristStatus, allTouristsStatus, childrenStatus} = bookingFields[fieldKey]
            return (
                (isMainTourist && mainTouristStatus === statusKey) ||
                (isChild && childrenStatus === statusKey) ||
                (!isMainTourist && !isChild && allTouristsStatus === statusKey)
            )
        }
    }

    get isTouristBookingFieldDisabled() {
        return (fieldKey, isMainTourist, isChild, specifiedTouristBookingFields) =>
            this.isTouristBookingField(fieldKey, 'DISABLED', isMainTourist, isChild, specifiedTouristBookingFields)
    }

    get isTouristNotRequired() {
        return (tourist, isMainTourist, specifiedTouristBookingFields) =>
            (specifiedTouristBookingFields?.supplierConfigDetails || this.touristBookingFields.supplierConfigDetails)
                ?.guestsInformationRequiredType === 'MIXED' &&
            !isMainTourist &&
            !tourist.firstName &&
            !tourist.lastName
    }

    get ownProduct() {
        return supplierCode => supplierCode?.indexOf('company.') === 0
    }

    get isServiceStatusForbidden() {
        const forbiddenStatuses = [
            'Error',
            'No show',
            'Rejected',
            'Canceled',
            'Cancellation pending',
            'Confirmation pending',
            'Quote',
        ]
        return service => forbiddenStatuses.includes(service.status)
    }

    get isServiceVoucherAvailable() {
        const allowedFlightStatuses = ['CONFIRMED', 'ISSUED']
        const allowedStatuses = ['CONFIRMED']

        return service =>
            ((!this.isServiceFlight(service) && allowedStatuses.includes(service.status?.toUpperCase())) ||
                (this.isServiceFlight(service) && allowedFlightStatuses.includes(service.status?.toUpperCase()))) &&
            !this.isServiceVisa(service) &&
            !this.isBankFee(service)
    }

    get isServiceFlight() {
        return service => ['FLIGHT', 'OWNCHARTER'].includes(service.serviceType)
    }

    get isServiceVisa() {
        return service => ['OWNVISA'].includes(service.serviceType)
    }

    get isBankFee() {
        return service => service.serviceDetails?.[0]?.extraServiceProductType === 'BANK_FEE'
    }

    get plainPaymentStatuses() {
        return statuses =>
            statuses.reduce((statuses, status) => {
                if (['PAID', 'PARTIAL_PAID'].includes(status)) {
                    statuses.push(status)
                } else if (!statuses.includes('NOT_PAID')) {
                    statuses.push('NOT_PAID')
                }
                return statuses
            }, [])
    }
}
