import {interopDefault} from '@/utils/helpers'
const InsuranceSearchPage = () => interopDefault(import('~/pages/insurance/InsuranceSearchPage')),
    InsuranceBookingPage = () => interopDefault(import('~/pages/insurance/InsuranceBookingPage')),
    InsuranceConfirmationPage = () => interopDefault(import('~/pages/insurance/InsuranceConfirmationPage'))

export default [
    {
        path: '/search/insurance',
        component: InsuranceSearchPage,
        name: 'insuranceSearch',
    },
    {
        path: '/insurance/booking',
        component: InsuranceBookingPage,
        name: 'insuranceBooking',
    },
    {
        path: '/insurance/confirmation',
        component: InsuranceConfirmationPage,
        name: 'insuranceConfirmation',
    },
]
