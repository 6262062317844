import User from './User'

export default class CorporateUser extends User {
    constructor(person) {
        super(person)
    }

    get passport() {
        const activePassports = this.person.passports.filter(({active}) => active)
        if (activePassports.length) {
            const {issueDate, type, number, validTill: expiryDate} = activePassports[0]
            return {
                issueDate,
                type,
                number,
                expiryDate,
            }
        }

        return null
    }

    get isCorporate() {
        return true
    }

    get canSelectTourist() {
        return !this.isEmployeeOrExternalCorp
    }

    get canBeTourlead() {
        return this.isEmployeeOrExternalCorp
    }

    get takeClientMethod() {
        return 'persons'
    }
}
