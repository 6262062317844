import {deleteMethod, postMethod, putMethod} from '~/api'

async function post(body) {
    return await postMethod('/api/orderServices', body)
}

async function remove(request) {
    return await deleteMethod('/proxy/orderServices', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/orderServices/${id}`, request)
}

export {post, remove, put}
