import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'
import {homePageSettings, layoutSettings} from '@/utils/cms/cms-blank-states'
import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

const setI18nCustomTranslations = customTranslations => {
    Object.keys(customTranslations).forEach(locale => {
        appInstance.i18n.mergeLocaleMessage(locale, customTranslations[locale])
    })
}

@Module({name: 'cms', stateFactory: true, namespaced: true})
export default class CmsStore extends VuexModule {
    popularDestinations = []
    popularHotels = []
    layoutSettings = layoutSettings()
    customTranslations = {}
    homePageSettings = homePageSettings()
    blogItems = []
    topTours = []
    resorts = []

    @Mutation
    SET_CUSTOM_TRANSLATIONS(customTranslations) {
        this.customTranslations = customTranslations
    }

    @MutationAction({mutate: ['popularDestinations']})
    async loadPopularDestinations() {
        try {
            const destinations = await appInstance.$api.popularDestinations.get()
            const promises = destinations.map(destination =>
                appInstance.$api.locations.get({id: destination.id, limitCities: 0})
            )
            const data = await Promise.all(promises)
            const popularDestinations = data
                .map((rs, index) => {
                    if (!rs.cities.length) return null
                    const location = rs.cities[0]
                    Object.assign(location, destinations[index])
                    return location
                })
                .filter(Boolean)
            return {popularDestinations}
        } catch (e) {
            return {popularDestinations: []}
        }
    }

    @MutationAction({mutate: ['popularHotels']})
    async loadPopularHotels() {
        try {
            const popularHotels = await appInstance.$api.popularHotels.get()
            return {popularHotels}
        } catch (e) {
            return {popularHotels: []}
        }
    }

    @MutationAction({mutate: ['layoutSettings']})
    async loadLayoutSettings() {
        const titleTemplate = appInstance.head.titleTemplate
        try {
            const layoutSettings = await appInstance.$api.layoutSettings.get()
            if (!layoutSettings.head.titleTemplate) {
                layoutSettings.head.titleTemplate = titleTemplate
            }
            return {layoutSettings}
        } catch (e) {
            return {layoutSettings: layoutSettings(titleTemplate)}
        }
    }

    @MutationAction({mutate: ['homePageSettings']})
    async loadHomePageSettings() {
        try {
            const homePageSettings = await appInstance.$api.homePageSettings.get()
            return {homePageSettings}
        } catch (e) {
            return {homePageSettings: homePageSettings()}
        }
    }

    @MutationAction({mutate: ['blogItems']})
    async loadBlogItems() {
        try {
            const blogItems = await appInstance.$api.blog.get()
            return {blogItems}
        } catch (e) {
            return {blogItems: []}
        }
    }

    @MutationAction({mutate: ['topTours']})
    async loadTopTours() {
        try {
            const topTours = await appInstance.$api.topTours.get()
            return {topTours}
        } catch (e) {
            return {topTours: []}
        }
    }

    @MutationAction({mutate: ['resorts']})
    async loadResorts() {
        try {
            const resorts = await appInstance.$api.resorts.get(),
                cities = (
                    await Promise.all(
                        resorts.map(({cityId}) => appInstance.$api.locations.get({id: cityId, limitCities: 1}))
                    )
                ).reduce((cities, {cities: [city]}) => {
                    cities.push(city)
                    return cities
                }, [])
            const countryIds = [...new Set(cities.map(({countryId}) => countryId))],
                countries = (
                    await Promise.all(countryIds.map(id => appInstance.$api.locations.get({id, limitCountries: 1})))
                ).reduce((countries, {countries: [country]}) => {
                    countries.push(country)
                    return countries
                }, [])
            return {
                resorts: resorts.map(({cityId, ...resort}) => {
                    const {name: cityName, countryId} = cities.find(({id}) => parseInt(cityId, 10) === id),
                        {iso2Code: countryCode, name: countryName} = countries.find(({id}) => countryId === id)
                    return {...resort, cityName, countryCode: countryCode.toLowerCase(), countryName}
                }),
            }
        } catch (e) {
            return {resorts: []}
        }
    }

    @MutationAction({mutate: ['customTranslations']})
    async loadCustomTranslations() {
        try {
            const customTranslations = await appInstance.$api.customTranslations.get()
            setI18nCustomTranslations(customTranslations)
            return {customTranslations}
        } catch (e) {
            return {customTranslations: {}}
        }
    }

    @Action
    async serverInit() {
        await Promise.all([this.loadLayoutSettings(), this.loadCustomTranslations()])
    }

    @Action
    clientInit() {
        EventBus.$on(CHANGE_LOCALE_EVENT, this.loadLayoutSettings)
        if (this.store.app?.context?.nuxtState?.serverRendered) {
            setI18nCustomTranslations(this.customTranslations)
        }
    }
}
