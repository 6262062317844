import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {runtimeStore} from '@/utils/store-accessor'

const incrementAmount = (total, price) => {
    const currency = price.originalCurrency || price.currency,
        amount = price.originalAmount || price.amount
    total.amount +=
        currency === total.currency
            ? amount
            : Math.round(
                  ((amount * runtimeStore.exchangeRates[currency]) / runtimeStore.exchangeRates[total.currency]) * 100
              ) / 100
}
const initialZeroPrice = ({originalCurrency, currency}) => ({
    currency: originalCurrency || currency,
    amount: 0,
    loyaltyAmount: 0,
})

/**
 * For work with GPFE data price model - when currency same with originalCurrency - shown only currency and amount props
 * @param prices
 * @returns {*}
 */
export const calcTotalPrice = prices => {
    if (!prices?.length) return undefined
    return prices.reduce((total, price) => {
        incrementAmount(total, price)
        if (price.commission?.amount) {
            if (!total.commission) {
                total.commission = initialZeroPrice(prices[0])
            }
            incrementAmount(total.commission, price.commission)
        }
        if (price.loyaltyAmount) {
            total.loyaltyAmount += price.loyaltyAmount
        }
        return total
    }, initialZeroPrice(prices[0]))
}

/**
 * @param additionalOptions
 * @param extraServices
 * @returns {{price: *, supplierPrice: *}}
 */
export const calcExtraServicesTotalPrice = (additionalOptions, extraServices) => {
    const prices = [],
        supplierPrices = []
    additionalOptions?.extraServices?.forEach(({salesTerms, serviceRPH}) => {
        const quantity = extraServices.find(extraService => extraService.serviceRPH === serviceRPH)?.quantity
        if (quantity) {
            const {price, supplierPrice} = salesTermsRsTransformer(salesTerms)
            prices.push({
                amount: (price.originalAmount || price.amount) * quantity,
                currency: price.originalCurrency || price.currency,
            })
            if (supplierPrice) {
                supplierPrices.push({
                    amount: (supplierPrice.originalAmount || supplierPrice.amount) * quantity,
                    currency: supplierPrice.originalCurrency || supplierPrice.currency,
                })
            }
        }
    })
    return {
        price: calcTotalPrice(prices),
        supplierPrice: calcTotalPrice(supplierPrices),
    }
}
