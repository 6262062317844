import {
    activitiesRuntimeStore,
    authStore,
    carsRentRuntimeStore,
    cmsStore,
    cruisesRuntimeStore,
    extraServicesRuntimeStore,
    flightsRuntimeStore,
    hotelsRuntimeStore,
    hotelsStore,
    initialiseStores,
    insuranceRuntimeStore,
    packagesRuntimeStore,
    runtimeStore,
    supplierStore,
    toursRuntimeStore,
    transfersRuntimeStore,
} from '@/utils/store-accessor'
import * as modulesList from '@/store/modules'
import Vuex from 'vuex'

const initializer = store => initialiseStores(store)
export const plugins = [initializer]
export * from '@/utils/store-accessor'

export const modules = modulesList
export const actions = {
    async nuxtServerInit() {
        hotelsStore.serverInit()
        await authStore.serverInit()
        await Promise.all([runtimeStore.serverInit(), cmsStore.serverInit()])
    },
    nuxtClientInit() {
        runtimeStore.clientInit()
        cmsStore.clientInit()
        hotelsRuntimeStore.clientInit()
        flightsRuntimeStore.clientInit()
        packagesRuntimeStore.clientInit()
        activitiesRuntimeStore.clientInit()
        transfersRuntimeStore.clientInit()
        toursRuntimeStore.clientInit()
        extraServicesRuntimeStore.clientInit()
        supplierStore.clientInit()
        carsRentRuntimeStore.clientInit()
        insuranceRuntimeStore.clientInit()
        cruisesRuntimeStore.clientInit()
    },
}
export const createStore = () => {
    return new Vuex.Store({
        actions,
        modules,
        plugins,
    })
}
