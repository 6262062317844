import {interopDefault} from '@/utils/helpers'

const CruisesSearchPage = () => interopDefault(import('@/pages/cruises/CruisesSearchPage'))
const CruisePage = () => interopDefault(import('@/pages/cruises/CruisePage'))
const CruiseBookingPage = () => interopDefault(import('@/pages/cruises/CruiseBookingPage'))
const CruiseConfirmationPage = () => interopDefault(import('@/pages/cruises/CruiseConfirmationPage'))

export default [
    {
        path: '/search/cruises',
        component: CruisesSearchPage,
        name: 'cruises',
    },
    {
        path: '/cruise',
        component: CruisePage,
        name: 'cruise',
        beforeEnter: (to, from, next) => {
            if (!to.query.name) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/cruises/booking',
        component: CruiseBookingPage,
        name: 'cruiseBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/cruises/confirmation',
        component: CruiseConfirmationPage,
        name: 'cruiseConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
