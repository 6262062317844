import {Action, Module, Mutation} from 'vuex-module-decorators'
import {filters as hotelFilters} from '@/utils/hotels/hotels-blank-states'
import {filters as flightFilters} from '~src/utils/flights/flights-blank-states.src'
import {searchRequest, searchResponse} from '@/utils/packages/packages-blank-states'
import {packagesRuntimeStore} from '~/store'
import {appInstance} from '@/utils/app-accessor'
import {runtimeStore} from '@/utils/store-accessor'
import Vue from 'vue'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {convertPrice} from '@/utils/filters'
import {salesTermsRsTransformer} from '@/utils/api-helpers'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {searchRequest: searchRequest(), hotel: {}, flight: {}}
    )
}

@Module({name: 'packages', stateFactory: true, namespaced: true})
export default class PackagesStore extends ProductStoreBase {
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    searchExpirationTime = null

    flightSortKey = 'priceDeltaAsc'
    hotelSortKey = 'priceDeltaAsc'

    flightFilters = flightFilters()
    hotelFilters = hotelFilters()

    selectedHotelKey = null
    selectedFlightKey = null
    selectedHotel = null
    selectedFlight = null

    prepareBookRequest = {}
    prepareBookResponse = {}

    basket = []

    @Mutation
    ADD_TO_BASKET({offerKey, hotel, room, flight, price, searchRequest}) {
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.hotel.expirationTime))))
        const basketIndex = this.basket.findIndex(item => item.offerKey === offerKey),
            totalPrice = packagesRuntimeStore.totalPrice(room, flight, price),
            newBasketItem = {offerKey, hotel, room, flight, price, searchRequest, totalPrice}
        if (basketIndex !== -1) {
            Vue.set(this.basket, basketIndex, newBasketItem)
        } else {
            this.basket.push(newBasketItem)
        }
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(basketItem, 'currentPrice', price)
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.flightFilters = flightFilters()
        this.hotelFilters = hotelFilters()
        this.searchRequest = searchRequest
        this.selectedHotelKey = null
        this.selectedFlightKey = null
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.prepareBookRequest = {}
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    RESET_FLIGHT_FILTERS() {
        this.flightFilters = flightFilters()
    }

    @Mutation
    RESET_HOTEL_FILTERS() {
        this.hotelFilters = hotelFilters()
    }

    @Mutation
    SET_FLIGHT_FILTER(data) {
        this.flightFilters[data.key] = data.value
    }

    @Mutation
    SET_HOTEL_FILTER(data) {
        this.hotelFilters[data.key] = data.value
    }

    @Mutation
    SET_FLIGHT_SORT_KEY(sortKey) {
        this.flightSortKey = sortKey
    }

    @Mutation
    SET_HOTEL_SORT_KEY(sortKey) {
        this.hotelSortKey = sortKey
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        Vue.set(this.prepareBookRequest, data.prop, data.val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_SELECTED_HOTEL({offerKey, offer}) {
        this.selectedHotelKey = offerKey
        this.selectedHotel = offer
    }

    @Mutation
    SET_SELECTED_FLIGHT({offerKey, offer}) {
        this.selectedFlightKey = offerKey
        this.selectedFlight = offer
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            const basketItem = getBasketItem.call(this, rq.offerKey)
            const {offerKey} = await appInstance.$api.updatePackagePrice.put({
                offerKey: basketItem.offerKey,
                offersIds: [
                    {
                        type: 'FLIGHT',
                        offerId: basketItem.flight.offerKey,
                    },
                    {
                        type: 'ACCOMMODATION',
                        offerId: basketItem.room.groupedOffers[0].offerKey,
                    },
                ],
            })
            this.NEW_SEARCH(searchRequest())
            rq.offerKey = offerKey
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const prepareBookResponse = await appInstance.$api.prepareDynamicPackageBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(prepareBookResponse)
            const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
            if (convertPrice(price).amount !== convertPrice(basketItem.totalPrice).amount) {
                this.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
            }
            return prepareBookResponse
        } catch (e) {
            console.error(e)
            throw e
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get offerKey() {
        return this.searchResponse.offerKey
    }

    get selectedRoom() {
        return this.selectedHotel
            ? this.selectedHotel.rooms.find(({groupedOffers}) => groupedOffers[0].offerKey === this.selectedHotelKey)
            : null
    }

    get initialPrice() {
        return Object.entries(this.searchResponse.clientPrice).length !== 0
            ? this.searchResponse.clientPrice.price
            : {amount: 0}
    }

    get needUpdateRooms() {
        return hotel =>
            appInstance.$config.refreshHotelOffersSuppliers.includes(hotel.supplierCode) && !hotel.updatedRooms
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !packagesRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return this.searchResponse.offers.hotelsCount && this.searchResponse.offers.flightsCount
    }
}
