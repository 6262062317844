import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {cruisesStore, persistentStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {searchRequest, searchResponse} from '@/utils/cruises/cruises-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {PRODUCT_NAME} from '@/utils/cruises/cruises-const'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'
import {newSearch, search} from '@/utils/store-helpers'
import {onmessage} from '@/utils/worker-helpers'
import {loadCity} from '@/store/modules/tours/toursRuntime'

@Module({name: 'cruisesRuntime', stateFactory: true, namespaced: true})
export default class CruisesRuntimeStore extends ProductRuntimeBase {
    departureCity = {}
    destinationCity = {}

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @Mutation
    SET_DEPARTURE_CITY(val) {
        this.departureCity = val
    }

    @Mutation
    SET_DESTINATION_CITY(val) {
        this.destinationCity = val
    }

    @MutationAction({mutate: ['departureCity']})
    async loadDepartureCity(id) {
        if (!id) {
            return
        }

        return await loadCity.call(this, 'departureCity', id)
    }

    @MutationAction({mutate: ['destinationCity']})
    async loadDestinationCity(id) {
        return await loadCity.call(this, 'destinationCity', id)
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        onmessage.call(this, PRODUCT_NAME)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        cruisesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {}

    @Action
    reset() {
        newSearch.call(this, searchRequest(), searchResponse(), cruisesStore)
        this.RESET()
        cruisesStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest(), searchResponse(), cruisesStore)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq, searchResponse(), cruisesStore)
        EventBus.$emit(SEARCH_EVENT)
        await search.call(this, rq, PRODUCT_NAME, cruisesStore, appInstance.$api.searchCruises, 'products', [
            'duration',
            'price',
        ])
        this.STOP_SEARCH()
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'cruises', query}
        }
    }

    get infoPageLink() {
        return (product, searchRequest) => {
            const {query} = this.searchPageLink(searchRequest)
            query.name = product.name
            return {name: 'cruise', query}
        }
    }

    get searchRequestFromQuery() {
        return query => {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            } else {
                rq.childrenAges = rq.childrenAges.map(age => parseInt(age, 10))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }
    }
}
