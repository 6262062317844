import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
    FILTER_EVENT,
} from '@/utils/event-bus'
import {appInstance} from '@/utils/app-accessor'
import {axiosInstance} from '@/utils/axios-accessor'
import {isEqual} from '@/utils/helpers'
import {filters, searchRequest, searchResponse} from '~/utils/insurance/insurance-blank-states'
import {insuranceStore, persistentStore} from '@/store'

function newSearch(searchRequest) {
    this.stopSearch()
    this.SET_OFFERS([])
    this.SET_RUNTIME_FILTERS(runtimeFilters())
    insuranceStore.SET_SEARCH_RESPONSE(searchResponse())
    insuranceStore.NEW_SEARCH(searchRequest)
}

const runtimeFilters = () => ({})

function setSearchResponse(searchResponse) {
    if (insuranceStore.searchResponse.products.length === searchResponse.products.length) return
    insuranceStore.SET_FILTER({key: 'price', value: searchResponse.filters.price})
    insuranceStore.SET_SEARCH_RESPONSE(searchResponse)
    const {products} = searchResponse
    this.load(products)
}

async function loadCountry(point, id) {
    try {
        const {
            countries: [country],
        } = await appInstance.$api.locations.get({id})
        return {[point]: country}
    } catch (e) {
        return {[point]: {}}
    }
}

@Module({name: 'insuranceRuntime', stateFactory: true, namespaced: true})
export default class InsuranceRuntimeStore extends VuexModule {
    searchActiveCount = 0
    searchCTS = null

    location = {}
    offers = []

    filterActiveCount = 0
    runtimeFilters = runtimeFilters()

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Mutation
    SET_RUNTIME_FILTERS(filters) {
        this.runtimeFilters = filters
    }

    @Mutation
    SET_OFFERS(val) {
        this.offers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_SEARCH_CTS(cancelTokenSource) {
        this.searchCTS = cancelTokenSource
    }

    @MutationAction({mutate: ['location']})
    async loadLocation(id) {
        return await loadCountry.call(this, 'location', id)
    }

    @Mutation
    SET_DESTINATION_COUNTRY(country) {
        this.location = country
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        appInstance.$workers.insuranceWorker.onmessage = ({data}) => {
            if (data === 'load') {
                if (
                    isEqual({...filters(), price: insuranceStore.searchResponse.filters.price}, insuranceStore.filters)
                ) {
                    this.sort()
                } else {
                    this.filter()
                }
                return
            } else if (data === 'refresh') {
                return
            }
            this.STOP_FILTER()
            if (this.filterActive) return
            this.SET_OFFERS(data)
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        insuranceStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        if (this.searchCTS) this.searchCTS.cancel()
        newSearch.call(this, null)
        insuranceStore.RESET()
    }

    @Action
    async reload() {
        if (this.location.id) await this.loadLocation(this.location.id)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_SEARCH_CTS(cancelTokenSource)
            const searchResponse = await appInstance.$api.searchInsurance.get(rq, cancelTokenSource.token)
            setSearchResponse.call(this, searchResponse)
            insuranceStore.SET_SEARCH_RESPONSE(searchResponse)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    stopSearch() {
        if (this.searchCTS) this.searchCTS.cancel()
    }

    @Action
    load(offers = []) {
        appInstance.$workers.insuranceWorker.postMessage({action: 'load', offers})
    }

    @Action
    sort() {
        this.START_FILTER()
        appInstance.$workers.insuranceWorker.postMessage({
            action: 'filter',
            filters: {...insuranceStore.filters, ...this.runtimeFilters},
            sortKey: insuranceStore.sortFnName,
        })
    }

    @Action
    filter() {
        this.START_FILTER()
        appInstance.$workers.insuranceWorker.postMessage({
            action: 'filter',
            filters: {...insuranceStore.filters, ...this.runtimeFilters},
            sortKey: insuranceStore.sortFnName,
        })
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get touristsTotal() {
        return searchRequest => searchRequest.adults + searchRequest.childrenAges.length
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {...query} = searchRequest
            return {name: 'insuranceSearch', query}
        }
    }
}
