import {addDays, format} from 'date-fns'

export const searchRequest = () => ({
        convertToCurrency: null,
        packageTourId: null,
        departureCityId: null,
        arrivalCityId: null,
        startDateFrom: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        startDateTo: format(addDays(new Date(), 10), 'yyyy-MM-dd'),
        durationFrom: 3,
        durationTo: 6,
        adults: 2,
        childrenAges: [],
    }),
    searchResponse = () => ({
        offers: [],
        currency: null,
        filters: filters(),
    }),
    packageTourInfo = () => ({
        entries: [],
        images: [],
        locations: [],
        availabilities: [],
    }),
    filters = () => ({
        price: [Infinity, 0],
        packageDuration: [],
        nightsDuration: [],
        packageDate: [],
        category: [],
        tourName: [],
        mealTypes: [],
        hotelName: [],
        supplierName: [],
    }),
    bookingFields = () => ({
        BIRTHDATE: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'MANDATORY',
        },
        CITIZENSHIP: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        EMAIL: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
        PASSPORT_EXPIRE_DATE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PASSPORT_ISSUE_DATE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PASSPORT_NUMBER: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PHONE: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'DISABLED',
        },
        PREFIX: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
    })
