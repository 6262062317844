import {addDays, format} from 'date-fns'

export const filters = () => ({
        price: [Infinity, 0],
        name: null,
        langCodes: [],

        vehicleCategory: [],
        vehicleClass: [],
        transmissionType: [],
        fuelType: [],
        doorCount: [],
        passengerQuantity: [],
        baggageQuantity: [],
        features: [],
        carRentCompanyName: [],
        pickUpPoints: [],
        cancellationPolicy: [],
        supplierName: [],
    }),
    searchRequest = () => ({
        driverAge: 18,
        driverCitizenshipId: null,
        convertToCurrency: null,
        pickUpDateTime: format(addDays(new Date().setHours(10, 0, 0), 1), "yyyy-MM-dd'T'HH:mm"),
        pickUpCityId: null,
        dropOffDateTime: format(addDays(new Date().setHours(10, 0, 0), 7), "yyyy-MM-dd'T'HH:mm"),
        dropOffCityId: null,
        partialResponse: true,
    }),
    searchResponse = () => ({
        currency: null,
        filters: filters(),
        products: [],
    }),
    bookingFields = () => ({
        BIRTHDATE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        CITIZENSHIP: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'DISABLED',
        },
        EMAIL: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
        PASSPORT_EXPIRE_DATE: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'DISABLED',
        },
        PASSPORT_ISSUE_DATE: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'DISABLED',
        },
        PASSPORT_NUMBER: {
            mainTouristStatus: 'DISABLED',
            allTouristsStatus: 'DISABLED',
            childrenStatus: 'DISABLED',
        },
        PHONE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PREFIX: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
    })
