import {isOwnProduct, salesTermsRsTransformer} from '@/utils/api-helpers'

export const ERROR_MESSAGE_OFFER_NOT_AVAILABLE = 'error_message.offer_not_available',
    ERROR_MESSAGE_SOMETHING_WENT_WRONG = 'error_message.something_went_wrong',
    ERROR_MESSAGE_PREPARE_BOOK_UNKNOWN_ERROR = 'error_message.prepare_book_unknown_error',
    WARNING_MESSAGE_PRICE_CHANGED = 'warning_message.price_changed',
    STANDARD_ERROR_MESSAGES = [
        ERROR_MESSAGE_OFFER_NOT_AVAILABLE,
        ERROR_MESSAGE_SOMETHING_WENT_WRONG,
        ERROR_MESSAGE_PREPARE_BOOK_UNKNOWN_ERROR,
    ]

export const prepareBookResult = (prepareBookResponse, offersData = []) => {
    const prepareBookResponses = prepareBookResponse?.prepareBookResponses || [prepareBookResponse]
    return prepareBookResponses.map(
        (
            {
                vendorMessages = [],
                tips = [],
                status,
                warnings = [],
                errors = [],
                currentSalesTerms,
                adjustedPriceAmount,
                dueToConfirmDate,
                paymentByLoyaltyPoint,
            },
            index
        ) => {
            const {supplierCode, name} = offersData[index] || {}
            const {price, supplierPrice} = salesTermsRsTransformer(currentSalesTerms)
            const availableForBooking = status <= 2 || +status === 15
            const onRequest = status <= 1
            let priceChanged = false
            if (!isOwnProduct(supplierCode)) {
                vendorMessages.length = 0
            }
            const warningMessages = warnings.reduce((warningMessages, {token, message}) => {
                if (
                    token === 'external_price_has_been_changed' &&
                    !warningMessages.includes(WARNING_MESSAGE_PRICE_CHANGED)
                ) {
                    warningMessages.push(WARNING_MESSAGE_PRICE_CHANGED)
                    priceChanged = true
                } else if (!availableForBooking) {
                    warningMessages.push(message)
                }
                return warningMessages
            }, [])
            const errorMessages = errors.reduce((errorMessages, {token, message, errorType}) => {
                if (message) {
                    if (errorType === 'external') {
                        if (
                            ['external_23', 'external_150'].includes(token) &&
                            !errorMessages.includes(ERROR_MESSAGE_OFFER_NOT_AVAILABLE)
                        ) {
                            errorMessages.push(ERROR_MESSAGE_OFFER_NOT_AVAILABLE)
                        } else if (!errorMessages.includes(ERROR_MESSAGE_SOMETHING_WENT_WRONG)) {
                            errorMessages.push(ERROR_MESSAGE_SOMETHING_WENT_WRONG)
                        }
                    } else {
                        errorMessages.push(message)
                    }
                } else {
                    errorMessages.push(ERROR_MESSAGE_PREPARE_BOOK_UNKNOWN_ERROR)
                }
                return errorMessages
            }, [])
            if (!errorMessages.length && !availableForBooking) {
                errorMessages.push(ERROR_MESSAGE_PREPARE_BOOK_UNKNOWN_ERROR)
            }
            return {
                name,
                vendorMessages,
                tips,
                warningMessages,
                errorMessages,
                price,
                supplierPrice,
                availableForBooking,
                onRequest,
                priceChanged,
                adjustedPriceAmount,
                dueToConfirmDate,
                paymentByLoyaltyPoint,
            }
        }
    )
}

export const isPrepareBookResultClean = prepareBookResult =>
    prepareBookResult.every(
        ({vendorMessages, tips, warningMessages, errorMessages, availableForBooking}) =>
            availableForBooking &&
            !vendorMessages.length &&
            !tips.length &&
            !warningMessages.length &&
            !errorMessages.length
    )
