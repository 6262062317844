import {interopDefault} from '@/utils/helpers'

const CarsSearchPage = () => interopDefault(import('@/pages/cars/CarsSearchPage'))
const CarBookingPage = () => interopDefault(import('@/pages/cars/CarBookingPage'))
const CarConfirmationPage = () => interopDefault(import('@/pages/cars/CarConfirmationPage'))

export default [
    {
        path: '/search/cars',
        component: CarsSearchPage,
        name: 'carsRent',
    },
    {
        path: '/car/booking',
        component: CarBookingPage,
        name: 'carBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/car/confirmation',
        component: CarConfirmationPage,
        name: 'carConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
