import auth from '@/middleware/auth'
import {itineraryKey} from '@/utils/helpers'

export default function ({redirect, route, $cookies, $config}) {
    if (route.query.key) {
        if (itineraryKey($config.api.b2cKey, route.params.id) !== route.query.key) {
            redirect({name: 'home'})
        }
    } else {
        auth({redirect, route, $cookies})
    }
}
