import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {flightsStore, persistentStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {
    filters,
    searchRequest,
    searchResponse,
    searchScheduleResponse,
} from '~src/utils/flights/flights-blank-states.src'
import {appInstance} from '@/utils/app-accessor'
import Vue from 'vue'
import {PRODUCT_NAME} from '@/utils/flights/flights-const'
import {newSearch as newSearchBase, partialSearch} from '@/utils/store-helpers'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'
import {onmessage} from '@/utils/worker-helpers'
import flightsSort from '@/filters/flightsSort'
import flightsFilter from '~src/filters/flightsFilter.src'

async function scheduleSearch(searchRequest) {
    const searchResponse = await appInstance.$api.searchFlightSchedule.get(searchRequest)
    if (!searchResponse.routes.length) {
        searchResponse.routes = searchScheduleResponse(searchRequest.routes.length).routes
    }
    flightsStore.SET_SEARCH_SCHEDULE_RESPONSE(searchResponse)
    const routeIndex = 0
    flightsStore.RESET_SCHEDULE_FILTERS(routeIndex)

    //TODO Make by worker
    filter.call(this, searchResponse.routes[routeIndex].offers, {...filters(), duration: [0, 9000]})
}

function filter(offers, filters) {
    this.SET_OFFERS(flightsSort(flightsFilter(offers, filters), flightsStore.sortFnName))
}

export function newSearch(searchRequest) {
    newSearchBase.call(this, searchRequest, searchResponse(), flightsStore)
    this.SET_FLEXIBLE_DATES({
        startDates: [],
        endDates: [],
    })
    this.SET_FLEXIBLE_FLIGHTS([])
}

function newScheduleSearch(searchRequest) {
    if (this.scheduleSearchCTS) this.scheduleSearchCTS.cancel()
    flightsStore.SET_SEARCH_SCHEDULE_RESPONSE(searchScheduleResponse(searchRequest.routes.length))
    //this.SET_SCHEDULE_OFFERS({offers: []})
    this.SET_OFFERS([])
    flightsStore.NEW_SCHEDULE_SEARCH(searchRequest)
}

@Module({name: 'flightsRuntime', stateFactory: true, namespaced: true})
export default class FlightsRuntimeStore extends ProductRuntimeBase {
    searchType = 'price'
    routes = []
    arrivalLocation = null

    scheduleOffers = []
    scheduleSearchActiveCount = 0
    scheduleSearchCTS = null
    scheduleRouteIndex = 0

    availableOffers = []
    checkAvailabilityActive = false

    flexibleFlights = []
    flexibleDates = {
        startDates: [],
        endDates: [],
    }

    @Mutation
    RESET() {
        this.flexibleDates = {
            startDates: [],
            endDates: [],
        }
        this.flexibleFlights = []
        this.searchType = 'price'
        this.searchActiveCount = 0
    }

    @Mutation
    SET_ROUTES(val) {
        this.routes = [...val]
    }

    @Mutation
    SET_ROUTE_DATE({index, date}) {
        this.routes[index].date = date
    }

    @Mutation
    SET_FLEXIBLE_DATES(dates) {
        this.flexibleDates = dates
    }

    @Mutation
    SET_SCHEDULE_OFFERS({offers: val, route = null}) {
        if (route !== null) {
            Vue.set(this.scheduleOffers, route, val)
        } else {
            const index = this.scheduleOffers.length
            for (let i = 0; i < index; i++) {
                Vue.set(this.scheduleOffers, i, val)
            }
        }
    }

    @Mutation
    SET_AVAILABLE_OFFERS(val) {
        this.availableOffers = val
    }

    @Mutation
    SET_ACTIVE_ROUTE(val) {
        this.scheduleRouteIndex = val
    }

    @Mutation
    START_SCHEDULE_SEARCH() {
        this.scheduleSearchActiveCount++
    }

    @Mutation
    STOP_SCHEDULE_SEARCH() {
        this.scheduleSearchActiveCount--
    }

    @Mutation
    SET_CHECK_AVAILABILITY_ACTIVE(active) {
        this.checkAvailabilityActive = active
    }

    @Mutation
    SET_SEARCH_TYPE(type) {
        this.searchType = type
    }

    @Mutation
    SET_FLEXIBLE_FLIGHTS(flights) {
        this.flexibleFlights = flights
    }

    @MutationAction({mutate: ['routes']})
    async loadRoutes(queryRoutes) {
        try {
            const promises = []
            const routesCodes = []
            queryRoutes.forEach(route => {
                const codes = route.split(',')[0].split('-')
                routesCodes.push({
                    codes,
                    date: route.split(',')[1],
                })
                codes.forEach(code => {
                    const rq = {
                        pattern: code,
                        limit: 1,
                    }
                    promises.push(appInstance.$api.airports.get(rq))
                })
            })
            const routes = await Promise.all(promises)
            const maped = {}
            routes.forEach(([route]) => {
                maped[route.iataCode] = Object.assign({}, route, {parentName: route.countryName})
            })
            const result = routesCodes.map(routeCodes => {
                return {
                    departure: maped[routeCodes.codes[0]],
                    arrival: maped[routeCodes.codes[1]],
                    date: routeCodes.date,
                }
            })
            return {routes: result}
        } catch (e) {
            return {routes: []}
        }
    }

    @MutationAction({mutate: ['arrivalLocation']})
    async SET_ARRIVAL_LOCATION(id) {
        try {
            const {
                cities: [arrivalLocation],
            } = await appInstance.$api.locations.get({id, limitCities: 1})
            arrivalLocation.cityName = arrivalLocation.name

            return {arrivalLocation}
        } catch (e) {
            return {arrivalLocation: {}}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        onmessage.call(this, PRODUCT_NAME)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        flightsStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        newScheduleSearch.call(this, searchRequest())
        this.RESET()
        flightsStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    newScheduleSearch() {
        newScheduleSearch.call(this, searchRequest())
    }

    @Action
    async reload() {
        const promises = []
        promises.push(this.loadRoutes(flightsStore.searchRequest.routes))

        await Promise.all(promises)
    }

    @Action
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq, PRODUCT_NAME, flightsStore, appInstance.$api.searchFlight, 'offers', [
                'price',
                'duration',
            ])
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    async scheduleSearch(rq) {
        this.START_SCHEDULE_SEARCH()
        newScheduleSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await scheduleSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.STOP_SCHEDULE_SEARCH()
        }
    }

    @Action
    async checkAvailability(rq) {
        this.SET_CHECK_AVAILABILITY_ACTIVE(true)
        this.SET_AVAILABLE_OFFERS([])
        flightsStore.SET_AVAILABILITY_RESPONSE(searchResponse())
        flightsStore.SET_AVAILABILITY_REQUEST(rq)
        flightsStore.RESET_AVAILABLE_OFFER_FILTERS()
        EventBus.$emit(SEARCH_EVENT)
        try {
            const rs = await appInstance.$api.checkFlightPricesAndAvailability.post(rq)
            flightsStore.SET_AVAILABLE_OFFER_FILTER({key: 'price', value: [...rs.filters.price]})
            flightsStore.SET_AVAILABLE_OFFER_FILTER({key: 'duration', value: [...rs.filters.duration]})
            flightsStore.SET_AVAILABILITY_RESPONSE(rs)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.SET_CHECK_AVAILABILITY_ACTIVE(false)
        }
    }

    @Action
    async searchFlexibleFlights(rq) {
        this.SET_FLEXIBLE_FLIGHTS([])
        const {offers} = await appInstance.$api.searchFlight.get(rq)
        const grouped = {}
        offers.forEach(({itinerary}, index) => {
            const {departure} = itinerary[0].segments[0]
            const date = departure.date.split(' ')[0]
            if (!grouped[date]) {
                grouped[date] = []
            }
            grouped[date].push(offers[index])
        })
        const result = {}
        Object.entries(grouped).forEach(val => {
            const grouped = {}
            val[1].forEach(({itinerary}, index) => {
                const lastItinerary = itinerary.length - 1
                const lastSegment = itinerary[lastItinerary].segments.length - 1
                const {arrival} = itinerary[lastItinerary].segments[lastSegment]
                const date = arrival.date.split(' ')[0]
                if (!grouped[date]) {
                    grouped[date] = []
                }
                grouped[date].push(val[1][index])
            })
            result[val[0]] = grouped
        })
        const keys = Object.keys(result)
        keys.sort((a, b) => {
            return appInstance.$dateFns.compareAsc(appInstance.$dateFns.parseISO(a), appInstance.$dateFns.parseISO(b))
        })
        const startDates = keys
        const endDates = new Set()
        Object.values(result).forEach(part => {
            Object.keys(part).forEach(date => {
                endDates.add(date)
            })
        })
        const end = Array.from(endDates).sort((a, b) => {
            return appInstance.$dateFns.compareAsc(appInstance.$dateFns.parseISO(a), appInstance.$dateFns.parseISO(b))
        })
        this.SET_FLEXIBLE_FLIGHTS(result)
        this.SET_FLEXIBLE_DATES({
            startDates,
            endDates: end,
        })
    }

    @Action
    filter({offers, filters}) {
        filter.call(this, offers, filters)
    }

    get scheduleSearchActive() {
        return this.scheduleSearchActiveCount > 0
    }

    get isPriceMode() {
        return this.searchType === 'price'
    }

    get flightsPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'flights', query}
        }
    }

    get flightsSchedulePageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'flightsSchedule', query}
        }
    }

    get searchRequestFromQuery() {
        return query => {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), query)
            if (typeof rq.routes === 'string') {
                rq.routes = [rq.routes]
            }
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.directFlight = rq.directFlight === 'true' || rq.directFlight === true
            rq.baggageOnly = rq.baggageOnly === 'true' || rq.baggageOnly === true
            rq.refundableOnly = rq.refundableOnly === 'true' || rq.refundableOnly === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }
    }
}
