import CorporateUser from './CorporateUser'
import User from './User'
import TourAgency from './TourAgency'
import TourOperator from './TourOperator'

export default class UserFactory {
    static create(type, person) {
        switch (type) {
            case 'CORPORATOR':
                return new CorporateUser(person)
            case 'TOUR_AGENCY':
                return new TourAgency(person)
            case 'TOUR_OPERATOR':
                return new TourOperator(person)
            case 'TOUR_OPERATOR_1LEVEL':
                return new TourOperator(person)
            default:
                return new User(person)
        }
    }
}
