import {getMethod, postMethod, putMethod} from '~/api'

async function post(body) {
    return await postMethod('/api/communications', body)
}

async function get(request) {
    return await getMethod('/api/communications', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/communications/${id}`, request)
}

export {post, get, put}
