import {getMethod} from '~/api'
import {appInstance} from '@/utils/app-accessor'

async function get(request) {
    return await getMethod('/proxy/google/placeAutocomplete', {
        ...request,
        language: appInstance.i18n.locale,
        key: appInstance.$config.googleapisKey,
    })
}

export {get}
