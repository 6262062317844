import {getMethod, postMethod} from '~/api'

async function post(request) {
    return await postMethod('/proxy/reservationsFiltersConfiguration', request)
}

async function get(request) {
    return await getMethod('/proxy/reservationsFiltersConfiguration', request)
}

export {post, get}
