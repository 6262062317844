export default () => ({
    price: [Infinity, 0],
    category: [],
    location: [],
    type: [],
    district: [],
    distance: [],
    chain: [],
    services: [],
    classifications: [],
    shared: [],
    availability: [],
    roomTypes: [],
    bedTypes: [],
    mealTypes: [],
    tripadvisorRating: [],
    name: null,
    supplierCode: [],
    supplierName: [],
    popularServices: [],
    roomServices: [],
    specialOffer: [],
    cancellationPolicy: [],
    wishlist: [],
    supplierRating: [],
})
