import {appInstance} from '@/utils/app-accessor'

export default function ({route, $config, error, $cookies}) {
    const companyType = $cookies.get('companyType')

    if (
        (route.name === 'scheduler' && !$config.driverScheduler) ||
        (route.name === 'payments' && (companyType !== 'TOUR_OPERATOR_1LEVEL' || !$config.account.paymentScheduler))
    ) {
        error({statusCode: 403, message: appInstance.i18n.t('error_message.forbidden')})
    }
}
