import {interopDefault} from '@/utils/helpers'
const PackagesPage = () => interopDefault(import('~/pages/packages/PackagesPage'))
const PackageDetailPage = () => interopDefault(import('~/pages/packages/PackageDetailPage'))

const PackageBookingPage = () => interopDefault(import('~/pages/packages/PackageBookingPage'))
const PackageConfirmationPage = () => interopDefault(import('~/pages/packages/PackageConfirmationPage'))

export default [
    {
        path: '/search/packages',
        component: PackagesPage,
        name: 'packages',
    },
    {
        path: '/packages/hotel',
        component: PackageDetailPage,
        name: 'packageDetail',
    },

    {
        path: '/packages/booking',
        component: PackageBookingPage,
        name: 'packageBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/packages/confirmation',
        component: PackageConfirmationPage,
        name: 'packageConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
