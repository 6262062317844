let axiosInstance, axiosBaseInstance

function setAxiosInstance(instance) {
    axiosInstance = instance
}

function setAxiosBaseInstance(instance) {
    axiosBaseInstance = instance
}

export {setAxiosInstance, setAxiosBaseInstance, axiosInstance, axiosBaseInstance}
