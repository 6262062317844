import {Action, Mutation, VuexModule} from 'vuex-module-decorators'

export default class ProductRuntimeBase extends VuexModule {
    offers = []
    filterActiveCount = 0
    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    @Mutation
    SET_OFFERS(val) {
        this.offers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    get filterActive() {
        return this.filterActiveCount > 0
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }
}
