const productsMap = {
    hotels: 'accommodation',
    transfers: 'transfer',
    activities: 'excursion',
    flights: 'flight',
    packages: 'dynamicTour',
    tours: 'customComplexTour',
    carsRent: 'carRent',
    extraServices: 'ownExtraService',
    conferenceHall: 'ownExtraService',
    catering: 'ownExtraService',
    insurance: 'insurance',
    cruises: 'cruise',
    multiDayTours: 'customComplexTour',
}

export const activeProducts = ({activeProducts}, availableProducts) =>
    availableProducts.length
        ? activeProducts.reduce((activeProducts, product) => {
              if (availableProducts.includes(productsMap[product])) {
                  activeProducts.push(product)
              }
              return activeProducts
          }, [])
        : activeProducts
