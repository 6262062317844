import {interopDefault} from '@/utils/helpers'

const TransfersPage = () => interopDefault(import('@/pages/transfers/TransfersPage')),
    TransferPage = () => interopDefault(import('@/pages/transfers/TransferPage')),
    TransferBookingPage = () => interopDefault(import('@/pages/transfers/TransferBookingPage')),
    TransferConfirmationPage = () => interopDefault(import('@/pages/transfers/TransferConfirmationPage'))

export default [
    {
        path: '/search/transfers',
        component: TransfersPage,
        name: 'transfers',
    },
    {
        path: '/transfer',
        component: TransferPage,
        name: 'transfer',
        beforeEnter: (to, from, next) => {
            if (!to.query.name) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/transfers/booking',
        component: TransferBookingPage,
        name: 'transferBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/transfers/confirmation',
        component: TransferConfirmationPage,
        name: 'transferConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
