export const updateRooms = (hotels, hotel, rooms) => {
    const hotelIndex = hotels.findIndex(
        ({supplierCode, cityCode, hotelCode}) =>
            hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
    )
    hotels[hotelIndex].rooms = rooms
    hotels[hotelIndex].updatedRooms = true
}

export const updateWishlist = (hotels, hotel, isWishlist) => {
    const hotelIndex = hotels.findIndex(
        ({supplierCode, cityCode, hotelCode}) =>
            hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
    )
    hotels[hotelIndex].wishlist = isWishlist
}

export const updateHotelWishlist = (hotels, hotel, isWishlist) => {
    const hotelIndex = hotels.findIndex(
        ({supplierCode, cityCode, hotelCode}) =>
            hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
    )

    if (hotelIndex >= 0) {
        hotels[hotelIndex].wishlist = isWishlist
    } else if (isWishlist) {
        hotel.wishlist = isWishlist
        hotels.push(hotel)
    }
}
