export const filters = () => ({
        price: [Infinity, 0],
        name: null,
        shortDescription: null,
        supplierName: [],
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        countryId: null,
        cityId: null,
        startDate: null,
        adults: 1,
        childrenAges: [],
    }),
    searchResponse = () => ({
        filters: filters(),
        products: [],
    })
