import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'

async function setWeatherResponse(weatherResponse, cityId) {
    const localForage = await appInstance.$localForage.getItem('weather')
    const weather = localForage ? localForage : {}
    weather[cityId] = weatherResponse
    appInstance.$localForage.setItem('weather', weather)
    this.SET_WEATHER(weather)
}

@Module({name: 'weather', stateFactory: true, namespaced: true})
export default class WeatherStore extends VuexModule {
    weather = {}

    @Mutation
    SET_WEATHER(val) {
        this.weather = val
    }

    @Action
    async loadWeather({cityName, latitude, longitude, units, lang, cnt, cityId}) {
        try {
            const weatherResponse = await appInstance.$api.weather.get({
                cityName,
                latitude,
                longitude,
                units,
                lang,
                cnt,
            })
            await setWeatherResponse.call(this, weatherResponse, cityId)
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }
}
