import {filterOffers} from '@/utils/filter-helpers'

const fn = {}

fn.price = function (product, values) {
    const price = product.price || product.deltaPrice
    if (price) {
        return price.amount >= values[0] && price.amount <= values[1]
    }
    return true
}

fn.duration = function (product, values) {
    return product.sumDuration ? product.sumDuration >= values[0] && product.sumDuration <= values[1] : true
}

fn.supplierName = function (offer, values) {
    return values.includes(offer.supplierName)
}

fn.airline = function (offer, values) {
    return offer.itinerary.some(itinerary => {
        return itinerary.segments.some(({marketingAirlineName}) => {
            return marketingAirlineName === undefined
                ? values.includes('Airline is not indicated')
                : values.includes(marketingAirlineName)
        })
    })
}

fn.brandedFareName = function (offer, values) {
    return offer.itinerary.some(itinerary => {
        return itinerary.segments.some(({brandedFareName}) => values.includes(brandedFareName))
    })
}

fn.class = function (offer, values) {
    return offer.itinerary.some(itinerary => {
        return itinerary.segments.some(({class: flightClass}) => values.includes(flightClass.classType))
    })
}

fn.refundable = function (offer, values) {
    let val = 'true'
    if (offer.refundable === false) {
        val = 'false'
    }
    if (offer.refundable === undefined) {
        val = 'undefined'
    }
    return values.includes(val)
}

fn.stops = function (offer, values) {
    const stops = values.map(stopsCount => +stopsCount)
    return stops.length === 1 && stops[0] === 0
        ? offer.itinerary.every(({stopsCount}) => +stopsCount === 0)
        : offer.itinerary.some(({stopsCount}) => stops.includes(+stopsCount))
}

fn.flightNumber = function (offer, values) {
    return offer.itinerary.some(itinerary => {
        return itinerary.segments.some(({flightNumber}) => {
            const input = values.split(' ').join('').toLowerCase()
            if (values && values.length) {
                return input.includes(flightNumber)
            }
            return true
        })
    })
}

fn.startTime = function (offer, values) {
    const date = offer.itinerary[0].segments[0].departure.date
    return date ? isTimeInInterval(date, values) : true
}

fn.endTime = function (offer, values) {
    const date =
        offer.itinerary[offer.itinerary.length - 1].segments[
            offer.itinerary[offer.itinerary.length - 1].segments.length - 1
        ].arrival.date
    return date ? isTimeInInterval(date, values) : true
}

const isTimeInInterval = function (date, values) {
    date = date.replace(' ', 'T')
    const parsed = new Date(date)
    const startDate = new Date(date)
    const endDate = new Date(date)
    const startMinutes = values[0] % 60
    const startHours = (values[0] - startMinutes) / 60
    const endMinutes = values[1] % 60
    const endHours = (values[1] - startMinutes) / 60
    startDate.setHours(startHours)
    startDate.setMinutes(startMinutes)
    endDate.setHours(endHours)
    endDate.setMinutes(endMinutes)
    return parsed.getTime() >= startDate.getTime() && parsed.getTime() <= endDate.getTime()
}

export {fn}

export default function (offers, filters) {
    return filterOffers(offers, filters, fn)
}
