import ActivitiesWorker from 'worker-loader!@/filters/activitiesWorker'
import CarsWorker from 'worker-loader!@/filters/carsWorker'
import CruisesWorker from 'worker-loader!@/filters/cruisesWorker'
import ExtraServicesWorker from 'worker-loader!@/filters/extraServicesWorker'
import FlightsWorker from 'worker-loader!@/filters/flightsWorker'
import HotelsWorker from 'worker-loader!@/filters/hotelsWorker'
import InsuranceWorker from 'worker-loader!@/filters/insuranceWorker'
import PackagesWorker from 'worker-loader!@/filters/packagesWorker'
import ToursWorker from 'worker-loader!@/filters/toursWorker'
import TransfersWorker from 'worker-loader!@/filters/transfersWorker'

export default function (ctx, inject) {
    inject('workers', {
        activitiesWorker: new ActivitiesWorker(),
        carsRentWorker: new CarsWorker(),
        cruisesWorker: new CruisesWorker(),
        extraServicesWorker: new ExtraServicesWorker(),
        flightsWorker: new FlightsWorker(),
        hotelsWorker: new HotelsWorker(),
        insuranceWorker: new InsuranceWorker(),
        packagesWorker: new PackagesWorker(),
        toursWorker: new ToursWorker(),
        transfersWorker: new TransfersWorker(),
    })
}
