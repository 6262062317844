import Vue from 'vue'
import {
    convertPrice,
    price,
    formatPrice,
    priceFormat,
    imageUrl,
    dateFormat,
    dateShortFormat,
    dateTimeFormat,
    hoursFromDate,
    durationFormat,
    stripHtml,
    srcHost,
} from '~/utils/filters'

Vue.filter('convertPrice', convertPrice)
Vue.filter('price', price)
Vue.filter('formatPrice', formatPrice)
Vue.filter('priceFormat', priceFormat)
Vue.filter('imageUrl', imageUrl)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateShortFormat', dateShortFormat)
Vue.filter('dateTimeFormat', dateTimeFormat)
Vue.filter('hoursFromDate', hoursFromDate)
Vue.filter('durationFormat', durationFormat)
Vue.filter('stripHtml', stripHtml)
Vue.filter('srcHost', srcHost)
