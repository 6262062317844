import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {axiosInstance} from '~/utils/axios-accessor'
import {AUTH_EVENT, B2B_AUTH_EVENT, EventBus, RESET} from '@/utils/event-bus'
import {registeredPerson} from '@/utils/auth-blank-states'
import UserFactory from '../../models/User/UserFactory'
import {appInstance, ctxInstance} from '@/utils/app-accessor'
import {runtimeStore} from '@/utils/store-accessor'

//TODO Preferably change it in API
export function authorizationPersonRsTransformer(person) {
    person.firstName = person.name
    person.lastName = person.surname
    person.personId = person.id
    delete person.name
    delete person.surname
    delete person.id
    if (person.memberLevel === 1) {
        person.memberLevel = 'GUEST'
    } else if (person.memberLevel === 3) {
        person.memberLevel = 'REGISTERED'
    }
    person.password = null
    return person
}

@Module({name: 'auth', stateFactory: true, namespaced: true})
export default class AuthStore extends VuexModule {
    person = null
    registeredPerson = registeredPerson()
    company = {}
    companyType = ''
    availableProducts = []
    systemCurrency = null

    @Mutation
    SET_REGISTERED_PERSON(person) {
        this.registeredPerson = person
    }

    @Mutation
    SET_REGISTERED_PERSON_PROP(data) {
        this.registeredPerson[data.prop] = data.val
    }

    @Mutation
    SET_PERSON(person) {
        this.person = person
    }

    @Mutation
    SET_COMPANY_TYPE(companyType) {
        this.companyType = companyType
    }

    @Mutation
    SET_AVAILABLE_PRODUCTS(availableProducts) {
        this.availableProducts = availableProducts
    }

    @Mutation
    SET_SYSTEM_CURRENCY(systemCurrency) {
        this.systemCurrency = systemCurrency
    }

    @MutationAction({
        mutate: ['person', 'companyType', 'registeredPerson', 'company', 'availableProducts', 'systemCurrency'],
    })
    logOut() {
        appInstance.$cookies.remove('token')
        appInstance.$cookies.remove('role')
        appInstance.$cookies.remove('pid')
        appInstance.$cookies.remove('userTimeZone')
        appInstance.$cookies.remove('companyType')
        appInstance.$cookies.remove('systemCurrency')
        EventBus.$emit(RESET)
        return {
            person: null,
            companyType: null,
            registeredPerson: registeredPerson(),
            company: {},
            availableProducts: [],
            systemCurrency: null,
        }
    }

    @MutationAction({mutate: ['company']})
    async loadCompany() {
        try {
            const rs = await appInstance.$api.companies.get({relationship: 'OWN'})
            return {company: rs.object[0]}
        } catch (e) {
            return {company: {}}
        }
    }

    @MutationAction({mutate: ['person']})
    async loadPerson(id) {
        try {
            const personRs = await appInstance.$api.persons.get({id}),
                person = personRs.users[0]
            person.firstName = person.firstName || person.firstNameLocalized
            person.lastName = person.lastName || person.lastNameLocalized
            return {person}
        } catch (e) {
            return {person: null}
        }
    }

    @MutationAction({mutate: ['person']})
    updatePerson(personData) {
        const person = Object.assign({}, this.person, personData)
        return {person}
    }

    @Action
    authUser(rs) {
        if (rs.person) {
            rs.person = authorizationPersonRsTransformer(rs.person)
            appInstance.$cookies.set('pid', rs.person.personId)
        }
        this.SET_PERSON(rs.person)
        this.SET_COMPANY_TYPE(null)
        axiosInstance.setHeader('Token', rs.token)
        appInstance.$cookies.set('token', rs.token)
        if (rs.person) {
            appInstance.$cookies.set('role', 'client')
        } else {
            appInstance.$cookies.remove('role')
        }
        appInstance.$cookies.remove('userTimeZone')
        if (rs.person) {
            EventBus.$emit(AUTH_EVENT)
        }
        return rs.token
    }

    @Action({rawError: true})
    async register({prefix, firstName, lastName, contactPhone, email, password, memberLevel}) {
        runtimeStore.SET_AUTH_ACTIVE(true)
        try {
            await appInstance.$api.registration.post({
                prefix,
                firstName,
                lastName,
                contactPhone,
                email,
                password,
                memberLevel,
            })
            const rs = await appInstance.$api.authorization.get({login: email, password})
            this.authUser(rs)
        } finally {
            runtimeStore.SET_AUTH_ACTIVE(false)
        }
    }

    @Action({rawError: true})
    async auth(rq) {
        runtimeStore.SET_AUTH_ACTIVE(true)
        try {
            const rs = await appInstance.$api.authorization.get(rq)
            if (!ctxInstance.route.name?.includes('Booking') && rq) {
                EventBus.$emit(RESET)
            }
            if (ctxInstance.route.name?.includes('Confirmation') && rq) {
                await appInstance.router.push({name: 'home'})
            }
            const {availableProducts} = rs.settings
            if (availableProducts) {
                this.SET_AVAILABLE_PRODUCTS(availableProducts)
                appInstance.$cookies.set('availableProducts', availableProducts)
            }
            //TODO Preferably change it in API
            return this.authUser(rs)
        } finally {
            runtimeStore.SET_AUTH_ACTIVE(false)
        }
    }

    @Action({rawError: true})
    async authB2B(rq) {
        runtimeStore.SET_AUTH_ACTIVE(true)
        try {
            const {token, companyType, userId, availableProducts, userTimeZone, systemCurrency} =
                await appInstance.$api.authorization.get(rq)
            axiosInstance.setHeader('Token', token)
            await this.loadPerson(userId)
            this.SET_COMPANY_TYPE(companyType)
            this.SET_AVAILABLE_PRODUCTS(availableProducts)
            appInstance.$cookies.set('token', token)

            //TODO Need to load it on serverInit with separate API method
            appInstance.$cookies.set('role', this.person.role)
            appInstance.$cookies.set('pid', this.person.personId)
            appInstance.$cookies.set('userTimeZone', userTimeZone)
            appInstance.$cookies.set('companyType', companyType)
            appInstance.$cookies.set('availableProducts', availableProducts)

            EventBus.$emit(B2B_AUTH_EVENT)
            this.SET_REGISTERED_PERSON(registeredPerson())

            if (systemCurrency) {
                this.SET_SYSTEM_CURRENCY(systemCurrency)
                appInstance.$cookies.set('systemCurrency', systemCurrency)
            }

            EventBus.$emit(AUTH_EVENT)

            await this.loadCompany()
        } finally {
            runtimeStore.SET_AUTH_ACTIVE(false)
        }
    }

    get user() {
        return UserFactory.create(this.companyType, this.person)
    }

    get rolePrefix() {
        const role = appInstance.$cookies.get('role')
        if (!role) return null
        const split = role.split('.')
        return split.length === 2 ? split[0] : null
    }

    get personRole() {
        if (!this.person?.role) return null
        const split = this.person.role.split('.')
        return split.length === 2 ? split[1] : null
    }

    get orderType() {
        if (this.companyType === 'INTERNAL_SUPPLIER') return 'SUPPLIER'
        return ['TOUR_OPERATOR', 'TOUR_OPERATOR_1LEVEL'].includes(this.companyType) ? 'TO' : 'TA'
    }

    get isGuest() {
        return !appInstance.$cookies.get('role')
    }

    get isB2C() {
        return !this.companyType
    }

    get isB2B() {
        return this.isTourOperator || this.isCorporate || this.isAgency
    }

    get isAgency() {
        return this.companyType === 'TOUR_AGENCY'
    }

    get isTO1() {
        return this.companyType === 'TOUR_OPERATOR_1LEVEL'
    }

    get isTO2() {
        return this.companyType === 'TOUR_OPERATOR'
    }

    get isTourOperator() {
        return ['TOUR_OPERATOR', 'TOUR_OPERATOR_1LEVEL'].includes(this.companyType)
    }

    get isCorporate() {
        return this.companyType === 'CORPORATOR'
    }

    get isSupplier() {
        return this.companyType === 'INTERNAL_SUPPLIER'
    }

    get showPaymentAndInvoiceStatus() {
        if (this.personRole === 'dispatcher') return false

        return (
            (this.isB2C && appInstance.$config.showPaymentAndInvoiceStatus.b2c) ||
            (this.isB2B && appInstance.$config.showPaymentAndInvoiceStatus.b2b)
        )
    }

    get hasCorporateAccess() {
        return (
            this.isB2B &&
            (!this.isCorporate ||
                ['corp.director', 'corp.group_supervisor', 'corp.trip_coordinator'].includes(this.person.role))
        )
    }

    get isGuide() {
        return this.person?.role && ['to1l.guide'].includes(this.person.role)
    }

    get isDriver() {
        return this.person?.role && ['to1l.driver'].includes(this.person.role)
    }

    @Action
    async serverInit() {
        try {
            if (appInstance.$cookies.get('token')) {
                axiosInstance.setHeader('Token', appInstance.$cookies.get('token'))
                const role = appInstance.$cookies.get('role')
                if (role) {
                    const id = appInstance.$cookies.get('pid')
                    if (role === 'client') {
                        try {
                            const {persons} = await appInstance.$api.privateClients.get({id}),
                                person = persons[0]
                            person.firstName = person.firstName || person.firstNameLocalized
                            person.lastName = person.lastName || person.lastNameLocalized
                            this.SET_PERSON(person)
                        } catch (e) {
                            await this.auth()
                            if (this.store.$config.onlyRegistered) {
                                this.store.app.context.redirect({name: 'authorization'})
                            }
                        }
                    } else {
                        await Promise.all([this.loadPerson(id), this.loadCompany()])
                        if (this.person) {
                            this.SET_COMPANY_TYPE(this.company.type)
                            //TODO Need new API method GET settings by token
                            this.SET_AVAILABLE_PRODUCTS(appInstance.$cookies.get('availableProducts'))
                            this.SET_SYSTEM_CURRENCY(appInstance.$cookies.get('systemCurrency'))
                        } else {
                            await this.auth()
                            if (this.store.$config.onlyRegistered) {
                                this.store.app.context.redirect({name: 'b2b-auth'})
                            }
                        }
                    }
                }
            } else {
                await this.auth()
            }
        } catch (e) {
            return ctxInstance.error({
                statusCode: 500,
                message: appInstance.i18n.t('error_message.service_unavailable'),
            })
        }
    }
}
