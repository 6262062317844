export const applySingleFilter = (offer, filterKey, selectedFilterValues) => {
    if (selectedFilterValues.length === 0) return true
    if (offer[filterKey] || offer[filterKey] === 0) {
        const includes = value => selectedFilterValues.includes(value) || selectedFilterValues.includes(String(value)),
            value = offer[filterKey]
        if (Array.isArray(value)) {
            return value.some(value => includes(value))
        } else {
            return includes(value)
        }
    } else {
        return selectedFilterValues.indexOf('other') !== -1
    }
}

export const applyMultipleFilter = (offer, filterKey, selectedFilterValues) =>
    selectedFilterValues.every(selectedFilterValue => offer[filterKey].includes(selectedFilterValue))

export const filterOffers = (offers, filters, fn) =>
    Object.keys(filters).reduce((offers, filterKey) => {
        const selectedFilterValue = filters[filterKey]
        if (Array.isArray(selectedFilterValue) && selectedFilterValue.length === 0) {
            return offers
        } else if (!selectedFilterValue) {
            return offers
        } else {
            return offers.filter(offer => fn[filterKey](offer, selectedFilterValue, filterKey))
        }
    }, JSON.parse(JSON.stringify(offers)))
