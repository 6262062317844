import {getMethod, postMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/orders', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/orders/${id}`, request)
}

async function post(body) {
    return await postMethod('/api/orders', body)
}

export {get, put, post}
