import Vue from 'vue'
import {Mutation, Module, Action} from 'vuex-module-decorators'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {insuranceRuntimeStore, runtimeStore} from '@/store'
import {appInstance} from '@/utils/app-accessor'
import {UTCToDate} from '@/utils/helpers'
import {filters, searchRequest, searchResponse} from '~/utils/insurance/insurance-blank-states'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {salesTermsRsTransformer} from '@/utils/api-helpers'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            product: {offers: [], info: {}},
            info: {},
        }
    )
}

@Module({name: 'insurance', stateFactory: true, namespaced: true})
export default class InsuranceStore extends ProductStoreBase {
    searchExpirationTime = null
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    sortFnName = 'priceAsc'
    filters = filters()

    prepareBookRequest = {}
    prepareBookResponse = {}

    basket = []

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest
    }

    @Mutation
    RESET() {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest()

        this.prepareBookRequest = {}
        this.prepareBookResponse = {}
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_SEARCH_RESPONSE(rs) {
        this.searchResponse = rs
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        Vue.set(this.prepareBookRequest, data.prop, data.val)
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_SORT_FN_NAME(sort) {
        this.sortFnName = sort
    }

    @Mutation
    ADD_TO_BASKET(offerKey) {
        this.basket = this.basket.filter(item => {
            const offer = item.product.offers.find(offer => offer.offerKey === item.offerKey)
            return isBefore(new Date(), UTCToDate(parseISO(offer.expirationTime)))
        })
        if (getBasketItem.call(this, offerKey).offerKey) return
        const product = this.searchResponse.products.find(
            product => product.offers.findIndex(offer => offer.offerKey === offerKey) !== -1
        )
        this.basket.push({
            offerKey,
            product,
            searchRequest: {...this.searchRequest},
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.product.offers.find(offer => offer.offerKey === offerKey)
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Action({rawError: true})
    async prepareBook(req) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(req)
            const response = await appInstance.$api.prepareInsuranceBook.post(req)
            this.SET_PREPARE_BOOK_RESPONSE(response)
            return response
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get hasOffers() {
        return !!this.searchResponse.products.length
    }

    get basketItem() {
        return offerKey => {
            return getBasketItem.call(this, offerKey)
        }
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !insuranceRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }
}
