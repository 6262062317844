import {addDays, format} from 'date-fns'

export const searchRequest = () => ({
        convertToCurrency: null,
        locationId: null,
        startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), 10), 'yyyy-MM-dd'),
        daysCount: 3,
        adults: 2,
        childrenAges: [],
        birthdates: [],
    }),
    searchResponse = () => ({
        products: [],
        currency: null,
        filters: filters(),
    }),
    filters = () => ({
        price: [Infinity, 0],
        insuranceName: [],
        insuranceDescription: [],
        supplierName: [],
    }),
    bookingFields = () => ({
        BIRTHDATE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        CITIZENSHIP: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        EMAIL: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
        PASSPORT_NUMBER: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PHONE: {
            mainTouristStatus: 'MANDATORY',
            allTouristsStatus: 'MANDATORY',
            childrenStatus: 'MANDATORY',
        },
        PREFIX: {
            mainTouristStatus: 'OPTIONAL',
            allTouristsStatus: 'OPTIONAL',
            childrenStatus: 'OPTIONAL',
        },
    })
