import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {filters, searchRequest, searchResponse} from '@/utils/transfers/transfers-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {runtimeStore, transfersRuntimeStore} from '@/store'
import Vue from 'vue'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            product: {offers: [], info: {}},
            info: {},
        }
    )
}

@Module({name: 'transfers', stateFactory: true, namespaced: true})
export default class TransfersStore extends ProductStoreBase {
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    filters = filters()
    sortFnName = 'priceAsc'
    searchExpirationTime = null
    prepareBookRequest = {
        departure: {},
        arrival: {},
    }
    prepareBookReturnTransferRequest = {
        departure: {},
        arrival: {},
    }
    prepareBookResponse = {}
    basket = []
    bookingAdditionalOptions = []

    @Mutation
    ADD_TO_BASKET({info, offerKey}) {
        this.basket = this.basket.filter(item => {
            const offer = item.product.offers.find(offer => offer.offerKey === item.offerKey)
            return isBefore(new Date(), UTCToDate(parseISO(offer.expirationTime)))
        })
        if (getBasketItem.call(this, offerKey).offerKey) return

        const product = transfersRuntimeStore.offers.find(
            product => product.offers.findIndex(offer => offer.offerKey === offerKey) !== -1
        )

        this.basket.push({
            offerKey,
            product,
            info,
            searchRequest: this.searchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.product.offers.find(offer => offer.offerKey === offerKey)
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_FILTER(data) {
        this.filters[data.key] = data.value
    }

    @Mutation
    SET_SORT(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST(prepareBookReturnTransferRequest) {
        this.prepareBookReturnTransferRequest = prepareBookReturnTransferRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookReturnTransferRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_LOCATION_PROP({pointType, prop, val}) {
        if (!this.prepareBookRequest[pointType]) {
            this.prepareBookRequest[pointType] = {}
        }
        Vue.set(this.prepareBookRequest[pointType], prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_LOCATION_PROP({pointType, prop, val}) {
        Vue.set(this.prepareBookReturnTransferRequest[pointType], prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.filters = filters()
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.prepareBookRequest = {
            departure: {},
            arrival: {},
        }
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    //TODO Need refactoring and fixing - now REFRESH_BASKET_PRICE not work correct on booking by basket
    @Action({rawError: true})
    async prepareBook({rq, isReturnTransfer, returnTransferExtraServices}) {
        const request = {...rq}
        if (request.arrival?.type === 'hotel') {
            request.arrival = {
                type: request.arrival.type,
                hotelName: request.arrival.hotelName,
                hotelCode: request.arrival.hotelCode,
            }
        }
        if (request.departure?.type === 'hotel') {
            request.departure = {
                type: request.departure.type,
                hotelName: request.departure.hotelName,
                hotelCode: request.departure.hotelCode,
            }
        }
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            //TODO API workaround - ignore params in API layer
            request.tourists?.forEach(tourist => {
                if (tourist.passport) {
                    delete tourist.passport
                }
            })
            this.SET_PREPARE_BOOK_REQUEST(request)
            let rs
            if (isReturnTransfer) {
                const {basketKey} = await appInstance.$api.basket.post()
                const transferRs = await appInstance.$api.prepareBookTransfer.put(basketKey, request)
                const transferReturnRequest = {
                    ...this.prepareBookRequest,
                    ...this.prepareBookReturnTransferRequest,
                    ...(returnTransferExtraServices?.length && {extraServices: returnTransferExtraServices}),
                }
                this.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST(transferReturnRequest)

                /*if (transferReturnRequest.arrival.type === 'hotel') {
                    transferReturnRequest.arrival = {
                        type: transferReturnRequest.arrival.type,
                        hotelName: transferReturnRequest.arrival.name,
                        hotelCode: transferReturnRequest.arrival.hotelCode,
                    }
                }

                if (transferReturnRequest.departure.type === 'hotel') {
                    transferReturnRequest.departure = {
                        type: transferReturnRequest.departure.type,
                        hotelName: transferReturnRequest.departure.name,
                        hotelCode: transferReturnRequest.departure.hotelCode,
                    }
                }*/

                const transferReturnRS = await appInstance.$api.prepareBookTransfer.put(
                    basketKey,
                    transferReturnRequest
                )

                // rs = {...transferReturnRS.initResponse, basketKey}
                // rs.transferRs = {...transferRs.initResponse}
                // rs.transferReturnRs = {...transferReturnRS.initResponse}
                rs = {
                    prepareBookResponses: [transferRs.initResponse, transferReturnRS.initResponse],
                    basketKey,
                }
            } else {
                rs = await appInstance.$api.prepareBookTransfer.post(request)
            }
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !transfersRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return !!this.searchResponse.product.length
    }

    @Mutation
    ADD_BOOKING_ADDITIONAL_OPTIONS(options) {
        this.bookingAdditionalOptions.push(options)
    }

    @MutationAction({mutate: ['bookingAdditionalOptions']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
        }
    }

    @Action
    async loadBookingAdditionalOptions(offerKey) {
        try {
            const options = await appInstance.$api.transferAdditionalOptions.get({offerKey})
            this.ADD_BOOKING_ADDITIONAL_OPTIONS(options)
        } catch (error) {
            console.error(error)
        }
    }
}
