import {interopDefault} from '@/utils/helpers'
const TourPage = () => interopDefault(import('~/pages/tours/TourPage')),
    TourSearchPage = () => interopDefault(import('~/pages/tours/TourSearchPage')),
    TourBookingPage = () => interopDefault(import('~/pages/tours/TourBookingPage')),
    TourConfirmationPage = () => interopDefault(import('~/pages/tours/TourConfirmationPage'))

export default [
    {
        path: '/search/tour',
        component: TourSearchPage,
        name: 'tourSearch',
    },
    {
        path: '/tour',
        component: TourPage,
        name: 'tour',
    },
    {
        path: '/tour/booking',
        component: TourBookingPage,
        name: 'tourBooking',
    },
    {
        path: '/tour/confirmation',
        component: TourConfirmationPage,
        name: 'tourConfirmation',
    },
]
