import {interopDefault} from '@/utils/helpers'
const ExtraServicesPage = () => interopDefault(import('~src/pages/extraServices/ExtraServicesPage')),
    ExtraServicePage = () => interopDefault(import('~src/pages/extraServices/ExtraServicePage')),
    ExtraServiceBookingPage = () => interopDefault(import('~src/pages/extraServices/ExtraServiceBookingPage')),
    ExtraServiceConfirmationPage = () => interopDefault(import('~src/pages/extraServices/ExtraServiceConfirmationPage'))

export default [
    {
        path: '/search/extra-services',
        component: ExtraServicesPage,
        name: 'extraServices',
    },
    {
        path: '/extra-service',
        component: ExtraServicePage,
        name: 'extraService',
        beforeEnter: (to, from, next) => {
            if (!to.query.name) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/extra-service/booking',
        component: ExtraServiceBookingPage,
        name: 'extraServiceBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/extra-service/confirmation',
        component: ExtraServiceConfirmationPage,
        name: 'extraServiceConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
