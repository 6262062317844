import {getMethod, postMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/notificationTemplates', request)
}

async function post(request, params) {
    return await postMethod('/api/notificationTemplates', request, {params})
}

async function put(id, request) {
    return await putMethod(`/proxy/notificationTemplates/${id}`, request)
}

export {get, post, put}
