import {getMethod, postMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/documents', request)
}

async function post(request) {
    return await postMethod('/api/documents', request)
}

export {get, post}
