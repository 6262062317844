import {postFile, postMethod} from '~/api'

async function post(request) {
    const url = '/api/report'
    if (request.link) {
        return await postMethod(url, request)
    } else {
        return await postFile(url, request)
    }
}

export {post}
