import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {carsRentStore, persistentStore} from '@/store'
import {filters, searchRequest, searchResponse} from '@/utils/cars/cars-rent-blank-state'

import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    FILTER_EVENT,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {appInstance} from '@/utils/app-accessor'
import {isEqual} from '@/utils/helpers'
import {search} from '@/utils/store-helpers'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'

const runtimeFilters = () => ({})

function newSearch(searchRequest) {
    this.stopSearch()
    this.SET_OFFERS([])
    this.SET_RUNTIME_FILTERS(runtimeFilters())
    carsRentStore.SET_SEARCH_RESPONSE(searchResponse())
    carsRentStore.NEW_SEARCH(searchRequest)
}

async function loadCity(point, id) {
    try {
        const {
            cities: [city],
        } = await appInstance.$api.locations.get({id, limitCities: 1})
        return {[point]: city}
    } catch (e) {
        return {[point]: {}}
    }
}

@Module({name: 'carsRentRuntime', stateFactory: true, namespaced: true})
export default class CarsRentRuntimeStore extends ProductRuntimeBase {
    pickUpPoint = {}
    dropOffPoint = {}

    runtimeFilters = runtimeFilters()

    @Mutation
    SET_RUNTIME_FILTERS(filters) {
        this.runtimeFilters = filters
    }

    @Mutation
    SET_PICK_UP_POINT(val) {
        this.pickUpPoint = val
    }

    @Mutation
    SET_DROP_OFF_POINT(val) {
        this.dropOffPoint = val
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @MutationAction({mutate: ['pickUpPoint']})
    async loadPickUpPoint(cityId) {
        return await loadCity.call(this, 'pickUpPoint', cityId)
    }

    @MutationAction({mutate: ['dropOffPoint']})
    async loadDropOffPoint(cityId) {
        return await loadCity.call(this, 'dropOffPoint', cityId)
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        appInstance.$workers.carsRentWorker.onmessage = ({data}) => {
            if (data === 'load') {
                if (isEqual({...filters(), price: carsRentStore.searchResponse.filters.price}, carsRentStore.filters)) {
                    this.sort()
                } else {
                    this.filter()
                }
                return
            } else if (data === 'refresh') {
                return
            }
            this.STOP_FILTER()
            if (this.filterActive) return
            this.SET_OFFERS(data)
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        carsRentStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    filter() {
        this.START_FILTER()
        appInstance.$workers.carsRentWorker.postMessage({
            action: 'filter',
            filters: {...carsRentStore.filters, ...this.runtimeFilters},
            sortKey: carsRentStore.sortFnName,
        })
    }

    @Action
    sort() {
        this.START_FILTER()
        appInstance.$workers.carsRentWorker.postMessage({action: 'sort', sortKey: carsRentStore.sortFnName})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        this.RESET()
        carsRentStore.RESET()
    }

    @Action
    load(offers = []) {
        appInstance.$workers.carsRentWorker.postMessage({action: 'load', offers})
    }

    @Action
    async reload() {
        const promises = []
        if (this.pickUpPoint.id) promises.push(this.loadPickUpPoint(this.pickUpPoint.id))
        if (this.dropOffPoint.id) promises.push(this.loadDropOffPoint(this.dropOffPoint.id))
        await Promise.all(promises)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq, searchResponse(), carsRentStore)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await search.call(this, rq, 'carsRent', carsRentStore, appInstance.$api.searchCarRent, 'products')
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'carsRent', query}
        }
    }

    get offerMainImage() {
        return (offer, info) => {
            if (!info.images || !info.images.length) return {url: null}
            let mainImage = info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && info.images.length > 0) {
                mainImage = info.images[0]
            }
            return mainImage
        }
    }
}
