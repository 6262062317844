import {interopDefault} from '@/utils/helpers'
const ActivitiesPage = () => interopDefault(import('@/pages/activities/ActivitiesPage')),
    ActivityPage = () => interopDefault(import('@/pages/activities/ActivityPage')),
    ActivityBookingPage = () => interopDefault(import('@/pages/activities/ActivityBookingPage')),
    ActivityConfirmationPage = () => interopDefault(import('@/pages/activities/ActivityConfirmationPage'))

export default [
    {
        path: '/search/activities',
        component: ActivitiesPage,
        name: 'activities',
    },
    {
        path: '/activity',
        component: ActivityPage,
        name: 'activity',
        beforeEnter: (to, from, next) => {
            if (!to.query.name) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/activities/booking',
        component: ActivityBookingPage,
        name: 'activityBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/activities/confirmation',
        component: ActivityConfirmationPage,
        name: 'activityConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
