import User from './User'

export default class TourAgency extends User {
    constructor(person) {
        super(person)
    }

    get canSelectTourist() {
        return true
    }

    get canBeTourlead() {
        return false
    }

    get passport() {
        const activePassports = this.person.passports.filter(({active}) => active)
        if (activePassports.length) {
            const {issueDate, type, number, validTill: expiryDate} = activePassports[0]
            return {
                issueDate,
                type,
                number,
                expiryDate,
            }
        }

        return null
    }

    get takeClientMethod() {
        return 'privateClients'
    }
}
