import {addDays, format} from 'date-fns'
import Filters from '~src/utils/hotels/hotels-blank-filters.src'
import {appInstance} from '@/utils/app-accessor'

export const filters = Filters,
    searchRequest = () => ({
        cityId: null,
        startDate: format(addDays(new Date(), appInstance.$config.hotels.startDate), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), appInstance.$config.hotels.endDate), 'yyyy-MM-dd'),
        rooms: ['adults:2'],
        partialResponse: true,
        convertToCurrency: null,
    }),
    searchResponse = () => ({
        currency: null,
        filters: filters(),
        offersCount: 0,
    }),
    hotelInfoRequest = () => ({
        hotelCode: null,
        cityCode: null,
        supplierCode: null,
    }),
    hotelInfoResponse = () => ({
        hotelDescriptionInfo: {
            mainImage: {
                url: null,
                title: null,
            },
            images: {
                image: [],
            },
            localMappedHotelId: null,
            hotelId: null,
            hotelCode: null,
            mealTypes: {
                mealType: [],
            },
        },
    }),
    groupOffersBy = 'none'
