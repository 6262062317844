import Vue from 'vue'
import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {activitiesStore, persistentStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {searchRequest, searchResponse} from '@/utils/activities/activities-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {PRODUCT_NAME} from '@/utils/activities/activities-const'
import {onmessage} from '@/utils/worker-helpers'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'
import {newSearch, partialSearch} from '@/utils/store-helpers'
import {updateWishlist} from '@/utils/activities/activities-helpers'

@Module({name: 'activitiesRuntime', stateFactory: true, namespaced: true})
export default class ActivitiesRuntimeStore extends ProductRuntimeBase {
    city = {}

    @Mutation
    SET_CITY(val) {
        this.city = val
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @Mutation
    UPDATE_WISHLIST({id, isWishlist}) {
        const activity = this.offers.find(offer => offer.id === id)
        if (!activity) return
        activity.wishlist = isWishlist
        Vue.set(activity, 'wishlist', isWishlist)
    }

    @MutationAction({mutate: ['city']})
    async loadCity(id) {
        try {
            const {
                cities: [city],
            } = await appInstance.$api.locations.get({id, limitCities: 1})
            return {city}
        } catch (e) {
            return {city: {}}
        }
    }

    @Action
    async updateWishlist({id, isWishlist}) {
        const activity = {id}
        const cachedOffers = await appInstance.$localForage.getItem('activities')
        updateWishlist(cachedOffers, activity, isWishlist)
        appInstance.$localForage.setItem('activities', cachedOffers)
        appInstance.$workers.activitiesWorker.postMessage({
            action: 'refreshWishlist',
            activity,
            isWishlist,
        })
        this.UPDATE_WISHLIST({id, isWishlist})
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        onmessage.call(this, PRODUCT_NAME)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        activitiesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {
        if (this.city.id) await this.loadCity(this.city.id)
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest(), searchResponse(), activitiesStore)
        this.RESET()
        activitiesStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest(), searchResponse(), activitiesStore)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq, searchResponse(), activitiesStore)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq, PRODUCT_NAME, activitiesStore, appInstance.$api.searchActivities)
        } finally {
            this.STOP_SEARCH()
        }
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'activities', query}
        }
    }

    get infoPageLink() {
        return (product, searchRequest) => {
            const {query} = this.searchPageLink(searchRequest)
            query.productId = product.productId
            query.name = product.info.name
            return {name: 'activity', query}
        }
    }

    get searchRequestFromQuery() {
        return query => {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            } else {
                rq.childrenAges = rq.childrenAges.map(age => parseInt(age, 10))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.cityId = parseInt(rq.cityId, 10)
            rq.privateActivity = rq.privateActivity === 'true' || rq.privateActivity === true
            rq.sharedActivity = rq.sharedActivity === 'true' || rq.sharedActivity === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            delete rq.name
            return rq
        }
    }
}
