import {deleteMethod, getMethod, postMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/documentTemplates', request)
}

async function post(request) {
    return await postMethod('/api/documentTemplates', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/documentTemplates/${id}`, request)
}

async function remove(id) {
    return await deleteMethod(`/proxy/documentTemplates?id=${id}`)
}

export {get, post, put, remove}
