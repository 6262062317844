import {postMethod, putMethod} from '~/api'

async function post(personId, request) {
    return await postMethod(`/proxy/createPersonPassport/${personId}`, request)
}

async function put(personId, passportId, request) {
    return await putMethod(`/proxy/persons/${personId}/passport/${passportId}`, request)
}

export {post, put}
