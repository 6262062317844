import {postMethod} from '~/api'

async function post(request) {
    const urlParameters = Object.keys(request)
        .map(key => key + '=' + request[key])
        .join('&')
    return await postMethod('/api/modifyAccommodationService' + '?' + urlParameters)
}

export {post}
