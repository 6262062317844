import Vue from 'vue'
import VuetifyToast from 'vuetify-toast-snackbar'
import VCalendar from 'v-calendar'

/*import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});*/

Vue.use(VCalendar, {
    componentPrefix: 'vc', // Now use vc-calendar and vc-date-picker
})

export default ({$vuetify, app}) => {
    $vuetify.lang.current = app.i18n.locale
    Vue.use(VuetifyToast, {
        $vuetify,
        x: 'center',
        y: 'top',
    })
}
