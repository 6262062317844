import {getMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/availability', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/availability?id=${id}`, request)
}

export {get, put}
