import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import Vue from 'vue'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    FILTER_EVENT,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {hotelsStore, persistentStore, runtimeStore} from '@/store'
import {
    filters,
    hotelInfoRequest,
    hotelInfoResponse,
    searchRequest,
    searchResponse,
} from '@/utils/hotels/hotels-blank-states'
import {axiosInstance} from '@/utils/axios-accessor'
import {encodeSupplierCode, hotelNameToUrl, isEqual, later, matches} from '@/utils/helpers'
import {addSeconds} from 'date-fns'
import {appInstance} from '@/utils/app-accessor'
import {convertPrice} from '@/utils/filters'
import {sumPrice} from '@/utils/api-helpers'
import {partialSearchRequest} from '~src/utils/store-helpers'
import {setSliderFilter} from '@/utils/store-helpers'
import {srcHost} from '~src/utils/host-adapter'
import supplierIds from '@/api/suppliers'
import {updateRooms} from '@/utils/hotels/hotel-helpers'

const runtimeFilters = () => ({
    map: {
        center: null,
        radius: 3000,
    },
})

function newSearch(searchRequest) {
    this.stopSearch()
    this.SET_FILTERED_OFFERS([])
    this.SET_RUNTIME_FILTERS(runtimeFilters())
    hotelsStore.SET_SEARCH_RESPONSE(searchResponse())
    hotelsStore.NEW_SEARCH(searchRequest)
}

function newSingleHotelSearch(searchRequest) {
    this.stopSingleHotelSearch()
    hotelsStore.NEW_SINGLE_HOTEL_SEARCH(searchRequest)
}

function setSearchResponse(searchResponse, rangeFilters) {
    //TODO Need to some refactoring
    if (hotelsStore.searchResponse.offersCount === searchResponse.offers.length) return

    rangeFilters.forEach(key => {
        setSliderFilter(searchResponse, hotelsStore, key)
    })

    const {offers, ...sr} = searchResponse
    sr.offersCount = offers.length
    hotelsStore.SET_SEARCH_RESPONSE(sr)
    appInstance.$localForage.setItem('hotels', offers)
    this.load(offers)
}

async function partialSearch(request, rangeFilters) {
    const searchRequest = {...request}
    if (appInstance.$config.searchAccommodationByDistance && searchRequest.latitude && searchRequest.longitude) {
        delete searchRequest.cityId
    }
    const {searchKey} = await appInstance.$api.searchAccommodation.get(searchRequest)
    let searchResponse
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            const {offersCount, processed} = await appInstance.$api.partialSearchStatus.get({searchKey})
            if (hotelsStore.searchResponse.offersCount === offersCount) {
                this.SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(later(appInstance.$config.search.partialResponseInterval))
                await this.partialSearchTimeoutInstance.promise
                searchResponse = {processed}
                if (!processed) continue
            }
            searchResponse = await appInstance.$api.partialSearchResults.get(
                partialSearchRequest({...searchRequest, searchKey}),
                cancelTokenSource.token
            )
            if (!searchResponse) break
            setSearchResponse.call(this, searchResponse, rangeFilters)
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await partialSearch.call(this, searchRequest, rangeFilters)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.offers.length) {
        hotelsStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), appInstance.$config.search.offersLifetime))
    }
    return searchResponse
}

export async function getSupplierIdByCode(supplierCode) {
    if (supplierCode.indexOf('company.') === 0) {
        return +supplierCode.replace('company.', '')
    } else {
        return supplierIds[supplierCode]
    }
}

//TODO need refactoring with partialSearch
async function singleHotelSearch(searchRequest) {
    const sr = {...searchRequest}
    if (this.hotelInfoResponse.hotelDescriptionInfo.localMappedHotelId) {
        delete sr.cityCode
        delete sr.supplierCode
        delete sr.hotelCode
        sr.hotelId = this.hotelInfoResponse.hotelDescriptionInfo.localMappedHotelId
    } else if (this.hotelInfoResponse.hotelDescriptionInfo.hotelId) {
        delete sr.cityCode
        delete sr.supplierCode
        delete sr.hotelCode
        sr.hotelId = this.hotelInfoResponse.hotelDescriptionInfo.hotelId
    } else if (sr.hotelCode && sr.cityCode) {
        delete sr.cityId
        delete sr.hotelId
    }

    //Workaround for medistays
    if (sr.cityId && this.routeMap.originalCityId) {
        sr.cityId = this.routeMap.originalCityId
    }

    const {searchKey} = await appInstance.$api.searchAccommodation.get(sr)
    let searchResponse
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_SINGLE_HOTEL_SEARCH_CTS(cancelTokenSource)
            const {offersCount, processed} = await appInstance.$api.partialSearchStatus.get({searchKey})
            if (hotelsStore.singleHotelSearchResponse.offers.length === offersCount) {
                this.SET_SINGLE_HOTEL_SEARCH_TIMEOUT_INSTANCE(later(appInstance.$config.search.partialResponseInterval))
                await this.singleHotelSearchTimeoutInstance.promise
                searchResponse = {processed}
                if (!processed) continue
            }
            searchResponse = await appInstance.$api.partialSearchResults.get({searchKey}, cancelTokenSource.token)
            if (!searchResponse) break
            hotelsStore.SET_SINGLE_HOTEL_SEARCH_RESPONSE(searchResponse)
            // eslint-disable-next-line no-empty
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await singleHotelSearch.call(this, searchRequest)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.offers.length) {
        hotelsStore.SET_SINGLE_HOTEL_SEARCH_EXPIRATION_TIME(
            addSeconds(new Date(), appInstance.$config.search.offersLifetime)
        )
    }
    return searchResponse
}

export const minPriceRoomOffer = ({rooms}) => {
    //for khg
    const roomsSorted = [...rooms].sort((a, b) => !!a.marketPrice - !!b.marketPrice)

    if (!roomsSorted[0].price) {
        const priceKey = appInstance.$config.packagesNotDeltaPrice ? 'notDeltaPrice' : 'deltaPrice'
        return roomsSorted.reduce(
            (a, b) => (a[priceKey].amount < b[priceKey].amount || b[priceKey].amount === undefined ? a : b),
            roomsSorted[0]
        )
    }
    const searchRoomsCount = hotelsStore.searchRequest.rooms.length,
        currency = persistentStore.getCurrency(rooms[0]?.price.currency)
    if (searchRoomsCount === 1)
        return roomsSorted
            .filter(room => !room.soldOut)
            .reduce((a, b) => (a.price.amount < b.price.amount ? a : b), roomsSorted[0])
    const sumOffer = {price: {amount: 0, currency, loyaltyAmount: 0}}
    for (let rph = 1; rph <= searchRoomsCount; rph++) {
        const minPriceRoom = roomsSorted
            .filter(
                room => !room.soldOut && room.groupedOffers.findIndex(groupedOffer => groupedOffer.rph === rph) !== -1
            )
            .reduce((a, b) => (convertPrice(a.price).amount < convertPrice(b.price).amount ? a : b), {
                price: {amount: Infinity, currency},
            })
        const minPrice = minPriceRoom.price
        if (minPrice.amount !== Infinity) sumOffer.price.amount += convertPrice(minPrice).amount
        if (minPrice.taxesAndFeesExcluded) {
            if (!sumOffer.price.taxesAndFeesExcluded) {
                sumOffer.price.taxesAndFeesExcluded = minPrice.taxesAndFeesExcluded
            } else {
                sumOffer.price.taxesAndFeesExcluded = [
                    ...sumOffer.price.taxesAndFeesExcluded,
                    ...minPrice.taxesAndFeesExcluded,
                ]
            }
        }
        if (minPrice.taxesAndFeesIncludedSum) {
            if (!sumOffer.price.taxesAndFeesIncludedSum) {
                sumOffer.price.taxesAndFeesIncludedSum = {...minPrice.taxesAndFeesIncludedSum}
            } else {
                sumOffer.price.taxesAndFeesIncludedSum = sumPrice([
                    sumOffer.price.taxesAndFeesIncludedSum,
                    minPrice.taxesAndFeesIncludedSum,
                ])
            }
        }
        sumOffer.available = minPriceRoom.available
        sumOffer.price.loyaltyAmount += minPrice.loyaltyAmount
    }
    return sumOffer
}

export {newSearch, partialSearch}

@Module({name: 'hotelsRuntime', stateFactory: true, namespaced: true})
export default class HotelsRuntimeStore extends VuexModule {
    //TODO Rename to runtimeOffers (not only filtered - sorted)
    filteredOffers = []
    similarOffers = []

    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    singleHotelSearchActiveCount = 0
    singleHotelSearchCTS = null
    singleHotelSearchTimeoutInstance = null

    updateActiveOffers = []
    bookingActive = false
    city = {}
    hotelInfoRequest = hotelInfoRequest()
    hotelInfoResponse = hotelInfoResponse()

    filterActiveCount = 0
    runtimeFilters = runtimeFilters()

    ownProductPaxes = []
    routeMap = {cityId: null, hotelId: null}

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Mutation
    SET_RUNTIME_FILTERS(filters) {
        this.runtimeFilters = filters
    }

    @Mutation
    SET_CITY(city) {
        this.city = city
    }

    @Mutation
    SET_BOOKING_ACTIVE(active) {
        this.bookingActive = active
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
        this.partialSearchResultsCTS = null
        this.partialSearchTimeoutInstance = null
    }

    @Mutation
    START_SINGLE_HOTEL_SEARCH() {
        this.singleHotelSearchActiveCount++
    }

    @Mutation
    STOP_SINGLE_SEARCH() {
        this.singleHotelSearchActiveCount--
        this.singleHotelSearchCTS = null
        this.singleHotelSearchTimeoutInstance = null
    }

    @Mutation
    SET_FILTERED_OFFERS(filteredOffers) {
        this.filteredOffers = filteredOffers
    }

    @Mutation
    SET_SIMILAR_OFFERS(similarOffers) {
        this.similarOffers = similarOffers
    }

    @Mutation
    ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers.push({supplierCode, cityCode, hotelCode})
    }

    @Mutation
    REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers = this.updateActiveOffers.filter(
            item => !matches(item, {supplierCode, cityCode, hotelCode})
        )
    }

    @Mutation
    UPDATE_ROOMS({supplierCode, cityCode, hotelCode, rooms}) {
        const hotel = this.filteredOffers.find(
            offer => offer.supplierCode === supplierCode && offer.cityCode === cityCode && offer.hotelCode === hotelCode
        )
        if (!hotel) return
        hotel.rooms = rooms
        Vue.set(hotel, 'updatedRooms', true)
    }

    @Mutation
    UPDATE_WISHLIST({supplierCode, cityCode, hotelCode, isWishlist}) {
        const hotel = this.filteredOffers.find(
            offer => offer.supplierCode === supplierCode && offer.cityCode === cityCode && offer.hotelCode === hotelCode
        )
        if (!hotel) return
        hotel.wishlist = isWishlist
    }

    @Mutation
    UPDATE_COORDINATES({supplierCode, cityCode, hotelCode, coordinates}) {
        const hotel = this.filteredOffers.find(
            offer => offer.supplierCode === supplierCode && offer.cityCode === cityCode && offer.hotelCode === hotelCode
        )
        if (!hotel) return
        Vue.set(hotel, 'coordinates', coordinates)
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_CTS(cancelTokenSource) {
        this.singleHotelSearchCTS = cancelTokenSource
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.singleHotelSearchTimeoutInstance = timeoutInstance
    }

    @MutationAction({mutate: ['city']})
    async loadCity(id) {
        try {
            const {cities} = await appInstance.$api.locations.get({id, limitCities: 1})
            return {city: cities[0]}
        } catch (e) {
            return {city: {}}
        }
    }

    @MutationAction({mutate: ['hotelInfoResponse', 'hotelInfoRequest']})
    async loadHotelInfo(rq) {
        try {
            const rs = await appInstance.$api.hotelInfo.get(rq)
            return {hotelInfoResponse: rs, hotelInfoRequest: rq}
        } catch (e) {
            return {hotelInfoResponse: hotelInfoResponse(), hotelInfoRequest: hotelInfoRequest()}
        }
    }

    @MutationAction({mutate: ['ownProductPaxes']})
    async loadOwnProductPaxes() {
        try {
            const paxIds = hotelsStore.bookingAdditionalOptions.reduce((paxIds, roomOptions) => {
                if (roomOptions.alternativeMeals) {
                    roomOptions.alternativeMeals.forEach(({prices}) => {
                        prices.forEach(({paxId}) => {
                            paxIds.push(paxId)
                        })
                    })
                }
                return paxIds
            }, [])
            const paxesRs = await Promise.all(
                [...new Set(paxIds)].map(id => appInstance.$api.ownProductPaxes.get({id}))
            )
            return {
                ownProductPaxes: paxesRs.map(({paxes}) => paxes[0]),
            }
        } catch (e) {
            return {
                ownProductPaxes: [],
            }
        }
    }

    @MutationAction({mutate: ['routeMap']})
    async loadRouteMap(name) {
        const empty = {routeMap: {cityId: null, hotelId: null}}
        try {
            const hotel = await axiosInstance.$get(srcHost(`/proxy/hotel/${name}`))
            return hotel ? {routeMap: hotel} : empty
        } catch (e) {
            return empty
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        appInstance.$workers.hotelsWorker.onmessage = ({data}) => {
            if (data.similarHotels) {
                this.SET_SIMILAR_OFFERS(data.similarHotels)
                return
            } else if (data === 'load') {
                console.timeEnd('load')
                if (isEqual({...filters(), price: hotelsStore.searchResponse.filters.price}, hotelsStore.filters)) {
                    this.sort()
                } else {
                    this.filter()
                }
                return
            } else if (data === 'refresh') {
                console.timeEnd('load')
                return
            }
            this.STOP_FILTER()
            console.timeEnd('filter')
            if (this.filterActive) return
            //this.SET_FILTERED_OFFERS(JSON.parse(new TextDecoder().decode(data)))
            this.SET_FILTERED_OFFERS(data)
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        hotelsStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        newSingleHotelSearch.call(this, null)
        hotelsStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    newSingleHotelSearch() {
        newSingleHotelSearch.call(this, null)
    }

    @Action
    async reload() {
        const promises = []
        if (this.hotelInfoRequest.hotelCode) promises.push(this.loadHotelInfo(this.hotelInfoRequest))
        if (this.city.id) promises.push(this.loadCity(this.city.id))
        await Promise.all(promises)
    }

    @Action
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq, ['price'])
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    @Action({rawError: true})
    async singleHotelSearch(rq) {
        this.START_SINGLE_HOTEL_SEARCH()
        newSingleHotelSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await singleHotelSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.STOP_SINGLE_SEARCH()
        }
    }

    @Action
    stopSingleHotelSearch() {
        if (this.singleHotelSearchCTS) this.singleHotelSearchCTS.cancel()
        if (this.singleHotelSearchTimeoutInstance) this.singleHotelSearchTimeoutInstance.cancel()
    }

    @Action
    async updateWishlist({supplierCode, cityCode, hotelCode, isWishlist}) {
        const hotel = {supplierCode, cityCode, hotelCode}
        appInstance.$workers.hotelsWorker.postMessage({
            action: 'refreshWishlist',
            hotel,
            isWishlist,
        })
        this.UPDATE_WISHLIST({supplierCode, cityCode, hotelCode, isWishlist})
    }

    @Action({rawError: true})
    async updateRoomOffers({supplierCode, cityCode, hotelCode}) {
        const hotelInfoRq = {supplierCode, cityCode, hotelCode}
        this.ADD_UPDATE_ROOMS_ACTIVE(hotelInfoRq)
        try {
            const searchRequest = Object.assign({}, hotelsStore.searchRequest, {
                partialResponse: false,
                cityCode,
                hotelCode,
            })

            if (this.hotelInfoResponse.hotelDescriptionInfo.localMappedHotelId) {
                delete searchRequest.cityCode
                delete searchRequest.supplierCode
                delete searchRequest.hotelCode
                searchRequest.hotelId = this.hotelInfoResponse.hotelDescriptionInfo.localMappedHotelId
            } else if (this.hotelInfoResponse.hotelDescriptionInfo.hotelId) {
                delete searchRequest.cityCode
                delete searchRequest.supplierCode
                delete searchRequest.hotelCode
                searchRequest.hotelId = this.hotelInfoResponse.hotelDescriptionInfo.hotelId
            } else if (searchRequest.hotelCode && searchRequest.cityCode) {
                delete searchRequest.cityId
                delete searchRequest.hotelId
                searchRequest.supplierId = await getSupplierIdByCode(supplierCode)
            }

            //Workaround for medistays
            if (searchRequest.cityId && this.routeMap.originalCityId) {
                searchRequest.cityId = this.routeMap.originalCityId
            }

            const rs = await appInstance.$api.searchAccommodation.get(searchRequest)
            //TODO What to do if no offers here?
            if (!rs.offers.length) return
            const payload = {...hotelInfoRq, ...{rooms: rs.offers[0].rooms}}
            const cachedOffers = await appInstance.$localForage.getItem('hotels')
            updateRooms(cachedOffers, hotelInfoRq, payload.rooms)
            appInstance.$localForage.setItem('hotels', cachedOffers)
            console.time('load')
            appInstance.$workers.hotelsWorker.postMessage({
                action: 'refreshRooms',
                hotel: hotelInfoRq,
                rooms: payload.rooms,
            })
            this.UPDATE_ROOMS(payload)
            appInstance.$workers.hotelsWorker.postMessage({
                action: 'filterNoSort',
                filters: {...hotelsStore.filters, ...this.runtimeFilters},
                sortKey: hotelsStore.sortFnName,
            })

            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.REMOVE_UPDATE_ROOMS_ACTIVE(hotelInfoRq)
        }
    }

    @Action
    load(offers = []) {
        //const offers = new TextEncoder().encode(JSON.stringify(hotelsStore.searchResponse.offers)).buffer
        //hotelsWorker.postMessage({action: 'load', offers}, [offers])
        console.time('load')
        appInstance.$workers.hotelsWorker.postMessage({action: 'load', offers})
    }

    @Action
    filter() {
        this.START_FILTER()
        console.time('filter')
        appInstance.$workers.hotelsWorker.postMessage({
            action: 'filter',
            filters: {...hotelsStore.filters, ...this.runtimeFilters},
            sortKey: hotelsStore.sortFnName,
        })
    }

    @Action
    filterSimilar(info, radius = 3000) {
        const category = hotelsStore.category(info.stdCategory)
        const map = {
            center: {
                lat: info.latitude,
                lng: info.longitude,
            },
            radius,
        }
        appInstance.$workers.hotelsWorker.postMessage({
            action: 'similarHotels',
            filters: {...filters(), price: [0, Infinity], category: [category], map},
        })
    }

    @Action
    sort() {
        this.START_FILTER()
        console.time('filter')
        appInstance.$workers.hotelsWorker.postMessage({
            action: 'filter',
            filters: {...hotelsStore.filters, ...this.runtimeFilters},
            sortKey: hotelsStore.sortFnName,
        })
    }

    get filterActive() {
        return this.filterActiveCount > 0
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get singleHotelSearchActive() {
        return this.singleHotelSearchActiveCount > 0
    }

    get updateRoomsActive() {
        return ({supplierCode, cityCode, hotelCode}) =>
            this.updateActiveOffers.findIndex(item => matches(item, {supplierCode, cityCode, hotelCode})) !== -1
    }

    get needUpdateRooms() {
        return ({supplierCode, cityCode, hotelCode, updatedRooms}) =>
            appInstance.$config.refreshHotelOffersSuppliers.includes(supplierCode) &&
            !updatedRooms &&
            !this.updateRoomsActive({supplierCode, cityCode, hotelCode})
    }

    get searchRequestTouristsJSON() {
        return searchRequest => {
            return searchRequest.rooms.map(room => {
                const groups = room.match(/^adults:(?<adults>\d)(,childrenAges:(?<childrenAges>(\d{1,2},?)+)$)?/).groups
                return {
                    adults: parseInt(groups.adults),
                    childrenAges: groups.childrenAges ? groups.childrenAges.split(',').map(age => parseInt(age)) : [],
                }
            })
        }
    }

    get searchRequestTouristsTotal() {
        return searchRequest =>
            this.searchRequestTouristsJSON(searchRequest).reduce(
                (total, tourists) => total + tourists.adults + tourists.childrenAges.length,
                0
            )
    }

    get searchRequestRoomTouristsCount() {
        return (searchRequest, roomIndex) => {
            const tourists = this.searchRequestTouristsJSON(searchRequest)[roomIndex]
            return tourists.adults + tourists.childrenAges.length
        }
    }

    get roomQueryString() {
        return ({adults, childrenAges}) => {
            let str = `adults:${adults}`
            if (childrenAges.length) {
                str += `,childrenAges:${childrenAges.join(',')}`
            }
            return str
        }
    }

    get hotelsPageLink() {
        return searchRequest => {
            const {
                // eslint-disable-next-line no-unused-vars
                partialResponse,
                // eslint-disable-next-line no-unused-vars
                convertToCurrency,
                // eslint-disable-next-line no-unused-vars
                hotelId,
                // eslint-disable-next-line no-unused-vars
                hotelCode,
                // eslint-disable-next-line no-unused-vars
                cityCode,
                // eslint-disable-next-line no-unused-vars
                supplierCode,
                // eslint-disable-next-line no-unused-vars
                distance,
                // eslint-disable-next-line no-unused-vars
                latitude,
                // eslint-disable-next-line no-unused-vars
                longitude,
                ...query
            } = searchRequest
            return {name: 'hotels', query}
        }
    }

    get hotelPageLink() {
        return (offer, searchRequest) => {
            let {supplierCode, cityCode, hotelCode, name} = offer
            if (supplierCode) {
                supplierCode = encodeSupplierCode(supplierCode)
            }
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, hotelId, distance, latitude, longitude, ...query} = searchRequest
            return {
                name: 'hotel',
                params: {name: hotelNameToUrl(name)},
                query: {...query, supplierCode, cityCode, hotelCode},
            }
        }
    }

    get ownProduct() {
        return offer => runtimeStore.ownProduct(offer.supplierCode)
    }

    get minPriceRoomOffer() {
        return ({rooms}) => minPriceRoomOffer.call(this, {rooms})
    }

    get mealTypePrice() {
        return (prices, tourist) => {
            const filteredPrices = tourist.onExtrabed
                ? prices.filter(({placeType}) => placeType === 'Extra')
                : tourist.onWithoutPlace
                ? prices.filter(({placeType}) => placeType === 'WithoutPlace')
                : prices.filter(({placeType}) => placeType === 'Base')
            const paxCategory = tourist.type === 'adult' ? 'ADULTS' : 'CHILDREN'
            const {id} =
                this.ownProductPaxes.find(
                    pax =>
                        pax.category === paxCategory &&
                        (paxCategory === 'ADULTS' || (tourist.age >= pax.ageFrom && tourist.age <= pax.ageTo))
                ) || {}
            return filteredPrices.find(price => price.paxId === id)?.price
        }
    }

    get findSameRoomOffer() {
        return (newRooms, oldRoom) =>
            newRooms.find(
                ({name, mealTypeName, tariff}) =>
                    oldRoom.name === name && oldRoom.mealTypeName === mealTypeName && oldRoom.tariff === tariff
            )
    }

    get findRoomOffer() {
        return (rooms, offerKey) =>
            rooms.find(room => room.groupedOffers.findIndex(groupedOffer => groupedOffer.offerKey === offerKey) !== -1)
    }

    get findSameHotel() {
        return (hotels, {hotelCode, supplierCode}) =>
            hotels.find(hotel => hotel.hotelCode === hotelCode && hotel.supplierCode === supplierCode)
    }

    get findHotelByOfferKey() {
        return (hotels, offerKey) =>
            hotels.find(
                ({rooms}) => rooms.findIndex(({groupedOffers}) => groupedOffers[0].offerKey === offerKey) !== -1
            )
    }
}
