import {interopDefault} from '@/utils/helpers'

const FlightsPage = () => interopDefault(import('~/pages/flights/FlightsPage'))
const FlightsSchedulePage = () => interopDefault(import('~/pages/flights/FlightsSchedulePage'))
const FlightsAvailabilityPage = () => interopDefault(import('~/pages/flights/FlightsAvailabilityPage'))
const FlightBookingPage = () => interopDefault(import('~src/pages/flights/flightBookingPage.src'))
const FlightConfirmationPage = () => interopDefault(import('~/pages/flights/FlightConfirmationPage'))

export default [
    {
        path: '/search/flights',
        component: FlightsPage,
        name: 'flights',
    },
    {
        path: '/flights/schedule',
        component: FlightsSchedulePage,
        name: 'flightsSchedule',
    },
    {
        path: '/flights/availability/:hash',
        component: FlightsAvailabilityPage,
        name: 'flightsAvailability',
    },
    {
        path: '/flights/booking',
        component: FlightBookingPage,
        name: 'flightBooking',
        beforeEnter: (to, from, next) => {
            if (!to.query.offerKey) {
                next({name: 'home'})
            }
            next()
        },
    },
    {
        path: '/flights/confirmation',
        component: FlightConfirmationPage,
        name: 'flightConfirmation',
        beforeEnter: (to, from, next) => {
            if (!to.query.orderId) {
                next({name: 'home'})
            }
            next()
        },
    },
]
