import {interopDefault} from '@/utils/helpers'
const EmailTemplatesPage = () => interopDefault(import('@/pages/admin/EmailTemplatesPage')),
    EmailEditorPage = () => interopDefault(import('@/pages/admin/EmailEditorPage')),
    DocumentTemplatesPage = () => interopDefault(import('@/pages/admin/DocumentTemplatesPage')),
    DocumentEditorPage = () => interopDefault(import('@/pages/admin/DocumentEditorPage'))

const emailTemplatePermissions = {
    companyType: 'TOUR_OPERATOR_1LEVEL',
    role: ['support', 'director', 'supervisor'],
}

export default [
    {
        path: '/email-templates',
        component: EmailTemplatesPage,
        name: 'emailTemplates',
        meta: {permissions: emailTemplatePermissions},
    },
    //TODO prefer use nested Routes, but https://github.com/nuxt-community/router-module/issues/106
    {
        path: '/email-editor/:id',
        component: EmailEditorPage,
        name: 'emailEditor',
        meta: {permissions: emailTemplatePermissions},
    },
    {
        path: '/document-templates',
        component: DocumentTemplatesPage,
        name: 'documentTemplates',
        meta: {permissions: emailTemplatePermissions},
    },
    {
        path: '/document-editor/:id',
        component: DocumentEditorPage,
        name: 'documentEditor',
        meta: {permissions: emailTemplatePermissions},
    },
    {
        path: '/document-editor',
        component: DocumentEditorPage,
        name: 'newDocumentEditor',
        meta: {permissions: emailTemplatePermissions},
    },
]
