import {deleteMethod, getMethod, postMethod} from '~/api'

async function remove(request) {
    return await deleteMethod('/proxy/wishlist', request)
}

async function post(request) {
    return await postMethod('/proxy/wishlist', request)
}

async function get(request) {
    return await getMethod('/proxy/wishlist', request)
}

export {post, get, remove}
