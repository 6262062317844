import {postMethod, putMethod} from '~/api'

async function post(request) {
    return await postMethod('/api/prepareBookTransfer', request)
}

async function put(basketKey, request) {
    return await putMethod(`/proxy/prepareBookTransfer/${basketKey}`, request)
}

export {post, put}
