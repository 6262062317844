import Vue from 'vue'
import {Mutation, Module, Action} from 'vuex-module-decorators'
import {filters, searchRequest, searchResponse} from '~/utils/tours/tours-blank-states'
import {runtimeStore, toursRuntimeStore} from '@/store'
import {appInstance} from '@/utils/app-accessor'
import {UTCToDate} from '@/utils/helpers'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {isAfter, isBefore, parseISO} from 'date-fns'
import ProductStoreBase from '@/store/modules/productStoreBase'

function getBasketItem(offerKey) {
    return this.basket.find(item => item.offerKey === offerKey) || {searchRequest: searchRequest(), offer: {info: {}}}
}

const singleTourSearchResponse = () => ({
    offers: [],
})

@Module({name: 'tours', stateFactory: true, namespaced: true})
export default class ToursStore extends ProductStoreBase {
    searchExpirationTime = null
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    sortFnName = 'dateAsc'
    groupOffersBy = 'none'
    filters = filters()
    singleTourSearchExpirationTime = null
    singleTourSearchRequest = null
    singleTourSearchResponse = singleTourSearchResponse()

    prepareBookRequest = {}
    prepareBookResponse = {}

    basket = []

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest
        this.singleTourSearchRequest = searchRequest
        this.singleTourSearchResponse = singleTourSearchResponse()
        this.singleTourSearchExpirationTime = null
    }

    @Mutation
    RESET() {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest()
        this.singleTourSearchRequest = null

        this.prepareBookRequest = {}
        this.prepareBookResponse = {}
    }

    @Mutation
    NEW_SINGLE_TOUR_SEARCH(searchRequest) {
        this.singleTourSearchRequest = searchRequest
        this.singleTourSearchResponse = singleTourSearchResponse()
        this.singleTourSearchExpirationTime = null
    }

    @Mutation
    SET_SINGLE_TOUR_SEARCH_REQUEST(rq) {
        this.singleTourSearchRequest = rq
    }

    @Mutation
    SET_SINGLE_TOUR_SEARCH_RESPONSE(rs) {
        this.singleTourSearchResponse = rs
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_SEARCH_RESPONSE(rs) {
        this.searchResponse = rs
    }

    @Mutation
    SET_SINGLE_TOUR_SEARCH_EXPIRATION_TIME(date) {
        this.singleTourSearchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    ADD_TO_BASKET(offerKey) {
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.offer.expirationTime))))
        if (getBasketItem.call(this, offerKey).offerKey) return

        let offer, searchRequest
        if (this.singleTourSearchResponse)
            offer = this.singleTourSearchResponse.offers.find(offer => offer.offerKey === offerKey)
        if (offer) searchRequest = Object.assign({}, this.singleTourSearchRequest)
        else {
            offer = toursRuntimeStore.offers.find(offer => offer.offerKey === offerKey)
            searchRequest = Object.assign({}, {...this.searchRequest, packageTourId: offer.info.id})
        }

        this.basket.push({
            offerKey,
            offer,
            searchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.offer
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_SORT_FN_NAME(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_GROUP_OFFERS_BY(val) {
        this.groupOffersBy = val
    }

    //TODO Need refactoring
    @Mutation
    UPDATE_OFFER({offerKey, selectedOfferKey, selectedOfferKeys}) {
        const setSelected = (index, entryIndex) => {
            Vue.set(
                this.singleTourSearchResponse.offers[index].entries[entryIndex],
                'selectedOfferKey',
                selectedOfferKey
            )
            if (selectedOfferKeys) {
                Vue.set(
                    this.singleTourSearchResponse.offers[index].entries[entryIndex],
                    'selectedOfferKeys',
                    selectedOfferKeys
                )
            }
        }
        const productOffersAdapter = product =>
            product.offers?.length ? product.offers : product.packageOffers?.length ? product.packageOffers : [product]
        this.singleTourSearchResponse.offers.forEach((tour, index) => {
            if (tour.offerKey === offerKey) {
                const entryIndex = tour.entries.findIndex(entry => {
                    if (entry.type === 'CAR') {
                        return entry.offer.offerKey === selectedOfferKey
                    } else {
                        return entry.offers?.find(offer => {
                            if (offer.rooms) {
                                if (!selectedOfferKeys) {
                                    return offer.rooms?.find(
                                        room => room.groupedOffers[0].offerKey === selectedOfferKey
                                    )
                                } else {
                                    return offer.rooms?.find(room =>
                                        selectedOfferKeys.includes(room.groupedOffers[0].offerKey)
                                    )
                                }
                            } else {
                                return productOffersAdapter(offer).find(offer => offer.offerKey === selectedOfferKey)
                            }
                        })
                    }
                })
                if (entryIndex !== -1) {
                    if (this.singleTourSearchResponse.offers[index].entries[entryIndex].obligatory) {
                        setSelected(index, entryIndex)
                        return false
                    } else if (!this.singleTourSearchResponse.offers[index].entries[entryIndex].obligatory) {
                        const key = this.singleTourSearchResponse.offers[index].entries[entryIndex].selectedOfferKey
                        if (key && key !== selectedOfferKey) {
                            setSelected(index, entryIndex)
                        } else if (!key) {
                            setSelected(index, entryIndex)
                        } else if (selectedOfferKeys) {
                            setSelected(index, entryIndex)
                        }
                        return false
                    }
                }
            }
        })
    }

    //TODO Need refactoring
    @Mutation
    DELETE_OFFER_TOUR({offerKey, selectedOfferKey}) {
        const productOffersAdapter = product =>
            product.offers?.length ? product.offers : product.packageOffers?.length ? product.packageOffers : [product]
        this.singleTourSearchResponse.offers.forEach((tour, index) => {
            if (tour.offerKey === offerKey) {
                const entryIndex = tour.entries.findIndex(entry => {
                    if (entry.type === 'CAR') {
                        return entry.offer.offerKey === selectedOfferKey
                    } else {
                        return entry.offers?.find(offer => {
                            if (offer.rooms) {
                                return offer.rooms?.find(room => room.groupedOffers[0].offerKey === selectedOfferKey)
                            } else {
                                return productOffersAdapter(offer).find(offer => offer.offerKey === selectedOfferKey)
                            }
                        })
                    }
                })
                if (entryIndex !== -1 && !this.singleTourSearchResponse.offers[index].entries[entryIndex].obligatory) {
                    Vue.delete(this.singleTourSearchResponse.offers[index].entries[entryIndex], 'selectedOfferKey')
                }
            }
        })
    }

    @Mutation
    SET_PREPARE_BOOK_ENTRY_PARAM_PROP({entryName, prop, val}) {
        const index = this.prepareBookRequest.entryParams.findIndex(entry => entry.entryName === entryName)
        Vue.set(this.prepareBookRequest.entryParams[index], prop, val)
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const rs = await appInstance.$api.preparePackageTourBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get hasOffers() {
        return !!this.searchResponse.offers.length
    }

    get hasSingleTourOffers() {
        return !!this.singleTourSearchResponse.searchKey
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get extraPrice() {
        return price => price
    }

    get isOffersExpired() {
        return () => this.hasOffers && !toursRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get isSingleHotelOffersExpired() {
        return () =>
            this.hasSingleTourOffers &&
            !toursRuntimeStore.singleTourSearchActive &&
            isAfter(new Date(), this.singleTourSearchExpirationTime)
    }
}
