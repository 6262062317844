export default class User {
    constructor(person) {
        this.person = person
    }

    get isEmployeeOrExternalCorp() {
        return this.person && (this.person.role === 'corp.employee' || this.person.role === 'corp.external_person')
    }

    get passport() {
        return this.person.passport
    }

    get isCorporate() {
        return false
    }

    get canSelectTourist() {
        return false
    }

    get canBeTourlead() {
        return true
    }

    get companyId() {
        return this.person.companyId
    }
}
