import {Mutation, VuexModule} from 'vuex-module-decorators'
import {convertPrice} from '@/utils/filters'
import {persistentStore} from '@/utils/store-accessor'
import {prepareBookResult} from '@/utils/PrepareBookAdapter'
import {calcTotalPrice} from '@/utils/price-utils'

export default class ProductStoreBase extends VuexModule {
    searchPage = 1

    @Mutation
    SET_SEARCH_PAGE(page) {
        this.searchPage = page
    }

    @Mutation
    SET_SEARCH_RESPONSE(searchResponse) {
        this.searchResponse = searchResponse
    }

    get orderTotalPrice() {
        return ({price, currentPrice, supplierPrice}, prebooked, selectedAddOns = [], prepareBookResponse) => {
            if (prebooked && prepareBookResponse) {
                const result = prepareBookResult(prepareBookResponse)
                const clientPrice = calcTotalPrice(result.map(({price}) => price)),
                    supplierPrice = calcTotalPrice(result.map(({supplierPrice}) => supplierPrice))
                return {
                    amount: clientPrice.amount,
                    commission: clientPrice.commission?.amount || 0,
                    currency: clientPrice.currency,
                    originalAmount: clientPrice.amount,
                    originalCurrency: clientPrice.currency,
                    loyaltyPoints: clientPrice.loyaltyAmount,
                    supplierPriceAmount: supplierPrice.amount,
                    supplierPriceOriginalCurrencyAmount: supplierPrice.amount,
                    supplierCommission: supplierPrice.commission?.amount || 0,
                    loyaltyAmount: clientPrice.loyaltyAmount,
                }
            }

            const offerPrice = prebooked && currentPrice ? currentPrice : price,
                originalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                originalAmount = offerPrice.originalAmount || offerPrice.amount
            const supplierPriceObj = supplierPrice?.originalAmount
                ? {amount: supplierPrice?.originalAmount, currency: supplierPrice?.originalCurrency}
                : {amount: supplierPrice?.amount, currency: supplierPrice?.currency}
            const convertedSupplierPrice = convertPrice(supplierPriceObj, persistentStore.getCurrency(originalCurrency))
            const convertedSupplierPriceOriginal = convertPrice(supplierPriceObj, originalCurrency)

            const totalOfferPrice = {
                amount: convertPrice(offerPrice).amount,
                currency: persistentStore.getCurrency(originalCurrency),
                originalAmount,
                originalCurrency,
                loyaltyAmount: offerPrice.loyaltyAmount,
                supplierPriceAmount: convertedSupplierPrice?.amount,
                supplierPriceOriginalCurrencyAmount: convertedSupplierPriceOriginal?.amount,
            }
            if (offerPrice?.commission) totalOfferPrice.commission = convertPrice(offerPrice.commission).amount
            if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                totalOfferPrice.supplierCommission =
                    supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount
            }
            for (const {
                product: [{offers}],
            } of selectedAddOns.filter(({free}) => !free)) {
                for (const {price, loyaltyPoints, supplierPrice} of offers) {
                    totalOfferPrice.amount += convertPrice(price).amount
                    if (originalCurrency === price.currency) {
                        totalOfferPrice.originalAmount += price.amount
                    } else {
                        totalOfferPrice.originalAmount += convertPrice(price, originalCurrency).amount
                    }
                    if (price.commission) {
                        totalOfferPrice.commission += convertPrice(price.commission).amount
                    }
                    if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                        totalOfferPrice.supplierCommission =
                            supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount
                    }
                    totalOfferPrice.loyaltyPoints += loyaltyPoints || 0
                    totalOfferPrice.loyaltyAmount += price.loyaltyAmount || 0
                }
            }
            return totalOfferPrice
        }
    }
}
