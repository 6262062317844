export default {
    en: {
        registration_certificate:
            'Vokrug sveta LLC, TIN 100997632, certificate of state  registration No. 100997632 dated 12.04.2001.',
    },
    ru: {
        registration_certificate:
            'ООО «Вокруг света», УНП 100997632, свидетельство о гос. регистрации № 100997632 от 12.04.2001г.',
    },
}
