import VuexPersistence from 'vuex-persist'

export default async ({store, $localForage, redirect}) => {
    const key = 'vuex'
    const cache = await $localForage.getItem(key)
    if (cache && cache.persistent.storageVersion !== CONFIG.storageVersion) {
        await $localForage.clear()
        redirect('/')
    }
    new VuexPersistence({
        key,
        modules: [
            'persistent',
            'hotels',
            'transfers',
            'activities',
            'flights',
            'packages',
            'tours',
            'extraServices',
            'carsRent',
            'insurance',
            'cruises',
        ],
        storage: $localForage,
        asyncStorage: true,
    }).plugin(store)
}
