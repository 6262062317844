import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import Vue from 'vue'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
    FILTER_EVENT,
} from '@/utils/event-bus'
import {axiosInstance} from '@/utils/axios-accessor'
import {appInstance} from '@/utils/app-accessor'
import {isEqual} from '@/utils/helpers'
import {addDays, format, isAfter} from 'date-fns'
import ToursWorker from 'worker-loader!@/filters/toursWorker'
import {toursStore, persistentStore} from '@/store'
import {packageTourInfo, searchResponse, searchRequest, filters} from '@/utils/tours/tours-blank-states'
import {convertPrice} from '@/utils/filters'

function newSearch(searchRequest) {
    this.stopSearch()
    this.SET_OFFERS([])
    this.SET_RUNTIME_FILTERS(runtimeFilters())
    toursStore.SET_SEARCH_RESPONSE(searchResponse())
    toursStore.NEW_SEARCH(searchRequest)
}

function newSingleTourSearch(searchRequest) {
    this.stopSingleTourSearch()
    toursStore.NEW_SINGLE_TOUR_SEARCH(searchRequest)
}

let toursWorker

const runtimeFilters = () => ({})

function setSearchResponse(searchResponse) {
    if (toursStore.searchResponse.offers.length === searchResponse.offers.length) return
    //TODO Need to add price filter check as in other products
    toursStore.SET_FILTER({key: 'price', value: searchResponse.filters.price})
    const {offers, ...sr} = searchResponse
    sr.offers = new Array(offers.length)
    toursStore.SET_SEARCH_RESPONSE(sr)
    appInstance.$localForage.setItem('tours', offers)
    this.load(offers)
}

export async function loadCity(point, id) {
    try {
        const {
            cities: [city],
        } = await appInstance.$api.locations.get({id, limitCities: 1})
        return {[point]: city}
    } catch (e) {
        return {[point]: {}}
    }
}

@Module({name: 'toursRuntime', stateFactory: true, namespaced: true})
export default class ToursRuntimeStore extends VuexModule {
    packageTours = []

    packageTourId = null
    packageTourInfo = packageTourInfo()

    searchActiveCount = 0
    searchCTS = null

    singleTourSearchActiveCount = 0
    singleTourSearchCTS = null
    singleTourSearchTimeoutInstance = null

    departureCity = {}
    arrivalCity = {}
    offers = []

    filterActiveCount = 0
    runtimeFilters = runtimeFilters()

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Mutation
    SET_RUNTIME_FILTERS(filters) {
        this.runtimeFilters = filters
    }

    @Mutation
    SET_OFFERS(offers) {
        this.offers = offers
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    START_SINGLE_TOUR_SEARCH() {
        this.singleTourSearchActiveCount++
    }

    @Mutation
    STOP_SINGLE_SEARCH() {
        this.singleTourSearchActiveCount--
        this.singleTourSearchCTS = null
        this.singleTourSearchTimeoutInstance = null
    }

    @Mutation
    SET_SINGLE_TOUR_SEARCH_CTS(cancelTokenSource) {
        this.singleTourSearchCTS = cancelTokenSource
    }

    @Mutation
    SET_SEARCH_CTS(cancelTokenSource) {
        this.searchCTS = cancelTokenSource
    }

    @Mutation
    SET_DEPARTURE_CITY(city) {
        this.departureCity = city
    }

    @Mutation
    SET_ARRIVAL_CITY(city) {
        this.arrivalCity = city
    }

    @Mutation
    UPDATE_CURRENT_DATE() {
        this.date = new Date()
    }

    //TODO Need refactoring
    @Mutation
    SELECT_OFFER({offerKey, selectedOfferKey, selectedOfferKeys}) {
        const setSelected = (index, entryIndex) => {
            Vue.set(this.offers[index].entries[entryIndex], 'selectedOfferKey', selectedOfferKey)
            if (selectedOfferKeys) {
                Vue.set(this.offers[index].entries[entryIndex], 'selectedOfferKeys', selectedOfferKeys)
            }
        }
        const productOffersAdapter = product =>
            product.offers?.length ? product.offers : product.packageOffers?.length ? product.packageOffers : [product]
        this.offers.forEach((tour, index) => {
            if (tour.offerKey === offerKey) {
                const entryIndex = tour.entries.findIndex(entry => {
                    if (entry.type === 'CAR') {
                        return entry.offer.offerKey === selectedOfferKey
                    } else {
                        return entry.offers?.find(offer => {
                            if (offer.rooms) {
                                if (!selectedOfferKeys) {
                                    return offer.rooms?.find(
                                        room => room.groupedOffers[0].offerKey === selectedOfferKey
                                    )
                                } else {
                                    return offer.rooms?.find(room =>
                                        selectedOfferKeys.includes(room.groupedOffers[0].offerKey)
                                    )
                                }
                            } else {
                                return productOffersAdapter(offer).find(offer => offer.offerKey === selectedOfferKey)
                            }
                        })
                    }
                })
                if (entryIndex !== -1) {
                    if (this.offers[index].entries[entryIndex].obligatory) {
                        setSelected(index, entryIndex)
                        return false
                    } else if (!this.offers[index].entries[entryIndex].obligatory) {
                        const key = this.offers[index].entries[entryIndex].selectedOfferKey
                        if (key && key !== selectedOfferKey) {
                            setSelected(index, entryIndex)
                        } else if (!key) {
                            setSelected(index, entryIndex)
                        } else if (selectedOfferKeys) {
                            setSelected(index, entryIndex)
                        }
                        return false
                    }
                }
            }
        })
        appInstance.$localForage.setItem('tours', this.offers)
        toursStore.UPDATE_OFFER({offerKey, selectedOfferKey, selectedOfferKeys})
    }

    //TODO Need refactoring
    @Mutation
    UNSELECT_OFFER({offerKey, selectedOfferKey}) {
        const productOffersAdapter = product =>
            product.offers?.length ? product.offers : product.packageOffers?.length ? product.packageOffers : [product]
        this.offers.forEach((tour, index) => {
            if (tour.offerKey === offerKey) {
                const entryIndex = tour.entries.findIndex(entry => {
                    if (entry.type === 'CAR') {
                        return entry.offer.offerKey === selectedOfferKey
                    } else {
                        return entry.offers?.find(offer => {
                            if (offer.rooms) {
                                return offer.rooms?.find(room => room.groupedOffers[0].offerKey === selectedOfferKey)
                            } else {
                                return productOffersAdapter(offer).find(offer => offer.offerKey === selectedOfferKey)
                            }
                        })
                    }
                })
                if (entryIndex !== -1 && !this.offers[index].entries[entryIndex].obligatory) {
                    Vue.delete(this.offers[index].entries[entryIndex], 'selectedOfferKey')
                }
            }
        })

        appInstance.$localForage.setItem('tours', this.offers)
        toursStore.DELETE_OFFER_TOUR({offerKey, selectedOfferKey})
    }

    @Mutation
    UPDATE_ENTRIES(entries) {
        entries.forEach(entry => {
            const index = this.offers[0].entries.findIndex(({entryName}) => entryName === entry.entryName)
            Vue.set(this.offers[0].entries, index, entry)
        })
    }

    @MutationAction({mutate: ['packageTours']})
    async loadPackageTours(request = {}) {
        try {
            const packageTours = await appInstance.$api.packageTours.get(request)
            return {packageTours}
        } catch (e) {
            return {packageTours: []}
        }
    }

    @MutationAction({mutate: ['packageTourId', 'packageTourInfo']})
    async loadPackageTourInfo(packageTourId) {
        try {
            const packageTourInfo = await appInstance.$api.packageTourInfo.get({packageTourId})
            packageTourInfo.itineraryDays.sort((a, b) => a.number - b.number)
            const locationResponses = await Promise.all(
                packageTourInfo.locations.map(location => appInstance.$api.locations.get({id: location.id}))
            )
            packageTourInfo.locations = locationResponses.map(locationRs => locationRs.cities[0])
            packageTourInfo.availabilities.forEach(availability => {
                const defaultDate = addDays(new Date(), 1)
                if (isAfter(defaultDate, new Date(availability.startDateFrom))) {
                    availability.startDateFrom = format(defaultDate, 'yyyy-MM-dd')
                }
            })
            return {packageTourId, packageTourInfo}
        } catch (e) {
            return {packageTourId, packageTourInfo: packageTourInfo()}
        }
    }

    @MutationAction({mutate: ['departureCity']})
    async loadDepartureCity(id) {
        return await loadCity.call(this, 'departureCity', id)
    }

    @MutationAction({mutate: ['arrivalCity']})
    async loadArrivalCity(id) {
        return await loadCity.call(this, 'arrivalCity', id)
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)

        toursWorker = new ToursWorker()
        toursWorker.onmessage = ({data}) => {
            if (data === 'load') {
                if (isEqual({...filters(), price: toursStore.searchResponse.filters.price}, toursStore.filters)) {
                    this.sort()
                } else {
                    this.filter()
                }
                return
            } else if (data === 'refresh') {
                return
            }
            this.STOP_FILTER()
            if (this.filterActive) return
            this.SET_OFFERS(data)
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        toursStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        if (this.searchCTS) this.searchCTS.cancel()
        newSingleTourSearch.call(this, null)
        toursStore.RESET()
    }

    @Action
    async reload() {
        const promises = []
        if (this.packageTourId) promises.push(this.loadPackageTourInfo(this.packageTourId))
        if (this.departureCity.id) promises.push(this.loadDepartureCity(this.departureCity.id))
        if (this.arrivalCity.id) promises.push(this.loadArrivalCity(this.arrivalCity.id))

        await Promise.all(promises)
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    stopSearch() {
        if (this.searchCTS) this.searchCTS.cancel()
    }

    @Action
    newSingleTourSearch() {
        newSingleTourSearch.call(this, null)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_SEARCH_CTS(cancelTokenSource)
            const searchResponse = await appInstance.$api.searchPackageTours.get(rq, cancelTokenSource.token)
            setSearchResponse.call(this, searchResponse)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action({rawError: true})
    async singleTourSearch(rq) {
        this.START_SINGLE_TOUR_SEARCH()
        newSingleTourSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_SINGLE_TOUR_SEARCH_CTS(cancelTokenSource)
            const searchResponse = await appInstance.$api.searchPackageTours.get(rq)
            toursStore.SET_SINGLE_TOUR_SEARCH_RESPONSE(searchResponse)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SINGLE_SEARCH()
        }
    }

    @Action
    stopSingleTourSearch() {
        if (this.singleTourSearchCTS) this.singleTourSearchCTS.cancel()
        if (this.singleTourSearchTimeoutInstance) this.singleTourSearchTimeoutInstance.cancel()
    }

    @Action
    load(offers = []) {
        toursWorker.postMessage({action: 'load', offers})
    }

    @Action
    sort() {
        this.START_FILTER()
        toursWorker.postMessage({action: 'sort', sortKey: toursStore.sortFnName})
    }

    @Action
    filter() {
        this.START_FILTER()
        toursWorker.postMessage({
            action: 'filter',
            filters: {...toursStore.filters, ...this.runtimeFilters},
            sortKey: toursStore.sortFnName,
        })
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get singleTourSearchActive() {
        return this.singleTourSearchActiveCount > 0
    }

    get touristsTotal() {
        return searchRequest => searchRequest.adults + searchRequest.childrenAges.length
    }

    //TODO Need refactoring
    get totalPrice() {
        const productOffersAdapter = product =>
            product.offers?.length ? product.offers : product.packageOffers?.length ? product.packageOffers : [product]
        return tour => {
            const amount = tour?.entries?.reduce(
                (totalPrice, entry) => {
                    let offerIndex
                    if (!['ACCOMMODATION', 'CAR', 'EVENT', 'EXCURSION'].includes(entry.type)) {
                        offerIndex = entry.selectedOfferKey
                            ? entry.offers.findIndex(offer => offer.offerKey === entry.selectedOfferKey)
                            : 0
                    }
                    let selectedOffer
                    switch (entry.type) {
                        case 'ACCOMMODATION':
                            entry.offers.forEach(offer => {
                                const calc = room => {
                                    if (room) {
                                        if (entry.obligatory) {
                                            totalPrice += convertPrice(room.deltaPrice).amount
                                        } else {
                                            totalPrice += convertPrice(room.notDeltaPrice).amount
                                        }
                                    }
                                }
                                if (entry.selectedOfferKeys) {
                                    const rooms = offer.rooms.filter(({groupedOffers}) =>
                                        entry.selectedOfferKeys.includes(groupedOffers[0].offerKey)
                                    )
                                    rooms.forEach(room => {
                                        calc(room)
                                    })
                                } else {
                                    const room = offer.rooms.find(
                                        ({groupedOffers}) => groupedOffers[0].offerKey === entry.selectedOfferKey
                                    )
                                    calc(room)
                                }
                            })
                            break
                        case 'EXCURSION':
                        case 'EVENT':
                            for (const product of entry.offers) {
                                for (const {deltaPrice, notDeltaPrice, offerKey} of product.offers) {
                                    if (offerKey === entry.selectedOfferKey) {
                                        if (entry.obligatory) {
                                            totalPrice += convertPrice(deltaPrice).amount
                                        } else {
                                            totalPrice += convertPrice(notDeltaPrice).amount
                                        }
                                    }
                                }
                            }
                            break
                        case 'EXTRA_SERVICE':
                            if (entry.selectedOfferKey && offerIndex >= 0) {
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(entry.offers[offerIndex].deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(entry.offers[offerIndex].notDeltaPrice).amount
                                }
                            }
                            break
                        case 'FLIGHT':
                            if (entry.selectedOfferKey && offerIndex >= 0) {
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(entry.offers[offerIndex].deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(entry.offers[offerIndex].notDeltaPrice).amount
                                }
                            }
                            break
                        case 'TRANSFER':
                            if (entry.selectedOfferKey && offerIndex >= 0) {
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(entry.offers[offerIndex].deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(entry.offers[offerIndex].notDeltaPrice).amount
                                }
                            }
                            break
                        case 'INSURANCE':
                            if (entry.selectedOfferKey && offerIndex >= 0) {
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(entry.offers[offerIndex].deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(entry.offers[offerIndex].notDeltaPrice).amount
                                }
                            }
                            break
                        case 'CRUISE':
                            if (entry.selectedOfferKey) {
                                for (const product of entry.offers) {
                                    selectedOffer = productOffersAdapter(product).find(
                                        ({offerKey}) => offerKey === entry.selectedOfferKey
                                    )
                                    if (selectedOffer) break
                                }
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(selectedOffer.deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(selectedOffer.notDeltaPrice).amount
                                }
                            }
                            break
                        case 'CAR':
                            if (entry.selectedOfferKey) {
                                if (entry.obligatory) {
                                    totalPrice += convertPrice(entry.offer.deltaPrice).amount
                                } else {
                                    totalPrice += convertPrice(entry.offer.notDeltaPrice).amount
                                }
                            }
                            break
                    }
                    return totalPrice
                },
                tour.price.amount ? tour.price.amount : 0
            )
            return {amount, currency: persistentStore.currency}
        }
    }

    get hasFlightOffers() {
        return offer => offer.entries.findIndex(entry => entry.type === 'FLIGHT' && entry.selectedOfferKey) !== -1
    }

    get firstFlightOffer() {
        return offer => {
            const entry = offer.entries.find(entry => entry.type === 'FLIGHT')
            return entry?.offers[0]
        }
    }

    get hasTransferOffers() {
        return offer => offer.entries.findIndex(entry => entry.type === 'TRANSFER') !== -1
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {...query} = searchRequest
            return {name: 'tourSearch', query}
        }
    }
}
