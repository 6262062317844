import {appInstance} from '~/utils/app-accessor'
import {persistentStore, runtimeStore} from '~/store'
import {fullUrl, srcHost as srcHostFn} from '~src/utils/host-adapter'
import {thumbnailUrl} from '@/utils/helpers'

export const convertPrice = (price, selectedCurrency = persistentStore.currency) => {
    let {amount, currency} = price
    if (price.originalCurrency) {
        amount = price.originalAmount
        currency = price.originalCurrency
    }
    if (selectedCurrency === 'ORIGINAL') {
        selectedCurrency = currency
    }
    if (selectedCurrency !== currency) {
        if (!runtimeStore.exchangeRates[currency] || !runtimeStore.exchangeRates[selectedCurrency]) {
            return {amount: NaN, currency: selectedCurrency}
        }
        amount =
            Math.round(
                ((amount * runtimeStore.exchangeRates[currency]) / runtimeStore.exchangeRates[selectedCurrency]) * 100
            ) / 100
    } else {
        amount = Math.round(amount * 100) / 100
    }
    return {amount, currency: selectedCurrency}
}

export const price = (price, selectedCurrency = persistentStore.currency) => {
    const {amount, currency} = convertPrice(price, selectedCurrency)
    return priceFormat(amount, currency)
}

export const formatPrice = ({amount, currency}) => priceFormat(amount, currency)

export const priceFormat = (amount, currency) => {
    if (isNaN(amount)) return ''
    const currencies = {
        USD: 'en-US',
        EUR: 'en-UK',
        GBP: 'en-GB',
    }
    if (!currencies[currency]) {
        return new Intl.NumberFormat('en-US', {style: 'currency', currency}).format(amount)
    }
    if (typeof amount !== 'string') {
        amount = amount.toFixed(2)
    }
    return appInstance.i18n.n(amount, 'currency', currencies[currency])
}

export const imageUrl = (url, size) => thumbnailUrl(url, size, fullUrl)

const dateFormatFn = (date, dateFormat) => {
    if (date) {
        let locale = appInstance.i18n.locale
        switch (locale) {
            case 'en':
                locale = 'en-GB'
                break
            case 'ar':
                locale = 'ar-SA'
        }
        return appInstance.$dateFns.format(date, dateFormat, {locale})
    }
    return ''
}

export const dateFormat = date => {
    return dateFormatFn(date, appInstance.$config.dateFormat.main)
}

export const dateShortFormat = date => {
    return dateFormatFn(date, appInstance.$config.dateFormat.short)
}

export const dateTimeFormat = date => {
    return dateFormatFn(date, appInstance.$config.dateFormat.time)
}

export const hoursFromDate = date => {
    return appInstance.$dateFns.format(date, 'HH:mm')
}

export const durationFormat = duration => {
    const minutes = Math.ceil(duration % 60)
    const hours = Math.ceil(duration - minutes) / 60
    return `${hours}${appInstance.i18n.t('date_formats.h')} ${minutes}${appInstance.i18n.t('date_formats.m')}`
}

export const handleNewLine = str => str?.replace(/\n/g, '<br>')

export const stripHtml = html => {
    return handleNewLine(new DOMParser().parseFromString(html, 'text/html').body.textContent || '')
}

export const srcHost = srcHostFn
