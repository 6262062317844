import {getMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/orderItinerary', request)
}

async function put(request) {
    return await putMethod('/proxy/orderItinerary', request)
}

export {get, put}
