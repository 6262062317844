import {getBase} from '~/api'
import {appInstance} from '@/utils/app-accessor'

async function get(request) {
    return await getBase('https://maps.googleapis.com/maps/api/geocode/json', {
        ...request,
        language: appInstance.i18n.locale,
        key: appInstance.$config.googleapisKey,
    })
}

export {get}
