import {getMethod, postMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/persons', request)
}

async function post(request) {
    return await postMethod('/api/persons', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/persons/${id}`, request)
}

export {get, post, put}
