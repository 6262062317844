import {addDays, format} from 'date-fns'

export const filters = () => ({
        price: [Infinity, 0],
        duration: [Infinity, 0],
        name: null,
        availability: [],
        cancellationPolicy: [],
        supplierName: [],
        cruiseLine: [],
        shipName: [],
        dates: [],
        mealTypeName: [],
        cabinType: [],
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        departureCityId: null,
        destinationCityId: null,
        startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
        durationFrom: null,
        durationTo: null,
        adults: 1,
        childrenAges: [],
    }),
    searchResponse = () => ({
        products: [],
        currency: null,
        filters: filters(),
    })
