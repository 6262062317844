export const layoutSettings = titleTemplate => ({
        logo: '/logo.png',
        socialLinks: [],
        footerMenu: [],
        script: null,
        head: {
            meta: [],
            titleTemplate,
        },
    }),
    homePageSettings = () => ({
        searchFormBackgrounds: {},
        topBlock: null,
        bottomBlock: null,
    })
