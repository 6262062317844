/**
 * @type {typeof import('@nuxt/vue-app') }
 * @property {object} $workers
 */
let appInstance
let ctxInstance

function setAppInstance(instance) {
    appInstance = instance
}

function setCtxInstance(ctx) {
    ctxInstance = ctx
}

export {setAppInstance, appInstance, setCtxInstance, ctxInstance}
